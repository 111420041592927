<div class="section section-feature cd-section" id="features">

<!--     *********     FEATURES 1      *********      -->
    <div class="features-1">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">Why our product is the best</h2>
                    <h5 class="description">This is the paragraph where you can write more details about your product. Keep you user engaged by providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't scroll to get here.</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="info">
                        <div class="icon icon-danger">
                            <i class="nc-icon nc-palette"></i>
                        </div>
                        <div class="description">
                            <h4 class="info-title">Beautiful Gallery</h4>
                            <p class="description">Spend your time generating new ideas. You don't have to think of implementing.</p>
                            <a href="#pkp" class="btn btn-link btn-danger">See more</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="info">
                        <div class="icon icon-danger">
                            <i class="nc-icon nc-bulb-63"></i>
                        </div>
                        <div class="description">
                            <h4 class="info-title">New Ideas</h4>
                            <p>Larger, yet dramatically thinner. More powerful, but remarkably power efficient.</p>
                            <a href="#pkp" class="btn btn-link btn-danger">See more</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="info">
                        <div class="icon icon-danger">
                            <i class="nc-icon nc-chart-bar-32"></i>
                        </div>
                        <div class="description">
                            <h4 class="info-title">Statistics</h4>
                            <p>Choose from a veriety of many colors resembling sugar paper pastels.</p>
                            <a href="#pkp" class="btn btn-link btn-danger">See more</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="info">
                        <div class="icon icon-danger">
                            <i class="nc-icon nc-sun-fog-29"></i>
                        </div>
                        <div class="description">
                            <h4 class="info-title">Delightful design</h4>
                            <p>Find unique and handmade delightful designs related items directly from our sellers.</p>
                            <a href="#pkp" class="btn btn-link btn-danger">See more</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--     *********     FEATURES 2      *********      -->
    <div class="features-2">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">Is this what you are looking for?</h2>
                    <h5 class="description">This is the paragraph where you can write more details about your product. Keep you user engaged by providing meaningful information. Remember that by this time, the user is curious, otherwise he wouldn't scroll to get here.</h5>
                    <br/>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="card" data-background="image" style="background-image: url('assets/img/sections/jan-sendereks.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">Design</h6>
                            <div class="card-icon">
                                <i class="nc-icon nc-world-2"></i>
                            </div>
                            <p class="card-description">Unlike fashionable design, it lasts many years – even in today’s throwaway society.</p>

                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-link btn-neutral">
                                    <i class="fa fa-book" aria-hidden="true"></i> Show more
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card" data-background="image" style="background-image: url('assets/img/sections/anders-jilden.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">UI Kits</h6>
                            <div class="card-icon">
                                <i class="nc-icon nc-single-copy-04"></i>
                            </div>
                            <p class="card-description">Imagine if you could go to the profile of a friend and instead of scrolling through...</p>

                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-link btn-neutral">
                                    <i class="fa fa-book" aria-hidden="true"></i> Show more
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card" data-background="image" style="background-image: url('assets/img/sections/uriel-soberanes.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">Development</h6>
                            <div class="card-icon">
                                <i class="nc-icon nc-time-alarm"></i>
                            </div>
                            <p class="card-description"> For starters, it seemed like a good idea to keep a running list of Vision investments.</p>

                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-link btn-neutral">
                                    <i class="fa fa-book" aria-hidden="true"></i> Show more
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!--     *********     FEATURES 3      *********      -->
    <div class="features-3">
        <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="container">
                                <h2 class="title">Your life will be much easier</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="info info-horizontal">
                                    <div class="icon icon-success">
                                        <i class="nc-icon nc-layout-11"></i>
                                    </div>
                                    <div class="description">
                                        <h4 class="info-title">Components</h4>
                                        <p>The kit comes with a huge number of components designed to look perfect together.</p>
                                        <a href="javascript:void(0)" class="btn btn-link">See more</a>
                                    </div>
                                </div>
                                <div class="info info-horizontal">
                                    <div class="icon icon-warning">
                                        <i class="nc-icon nc-palette"></i>
                                    </div>
                                    <div class="description">
                                        <h4 class="info-title">Beautiful Gallery</h4>
                                        <p>Spend your time generating new ideas. You don't have to think of implementing anymore.</p>
                                        <a href="javascript:void(0)" class="btn btn-link">See more</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="info info-horizontal">
                                    <div class="icon icon-danger">
                                        <i class="nc-icon nc-touch-id"></i>
                                    </div>
                                    <div class="description">
                                        <h4 class="info-title">Secured</h4>
                                        <p>Larger, yet dramatically thinner. More powerful, but remarkably power is efficient.</p>
                                        <a href="javascript:void(0)" class="btn btn-link">See more</a>
                                    </div>
                                </div>
                                <div class="info info-horizontal">
                                    <div class="icon icon-primary">
                                        <i class="nc-icon nc-delivery-fast"></i>
                                    </div>
                                    <div class="description">
                                        <h4 class="info-title">Fast Prototyping</h4>
                                        <p>Medium has a new head of product, while the startup behind the defunct texting app...</p>
                                        <a href="javascript:void(0)" class="btn btn-link">See more</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5 ml-auto">
                        <div class="iphone-container">
                            <img src="assets/img/sections/iphone.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>


<!--     *********     FEATURES 4     *********      -->
    <div class="features-4">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">Is this what you are looking for?</h2>
                    <h5 class="description">The more formalized version launching today brings a slate of corporate partners and a more structured application review process.</h5>
                </div>
            </div>
            <div class="row">

                <div class="col-md-6">
                    <div class="ipad-container">
                        <img src="assets/img/sections/ipad.png" />
                    </div>
                </div>

                <div class="col-md-4 offset-1">
                    <div class="info info-horizontal">
                        <div class="icon icon-info">
                            <i class="nc-icon nc-atom" aria-hidden="true"></i>
                        </div>
                        <div class="description">
                            <h4 class="info-title">For Developers</h4>
                            <p>The moment you use Paper Kit, you know you’ve never felt anything like it. With a single use, is's a powerfull UI Kit</p>
                        </div>
                    </div>

                    <div class="info info-horizontal">
                        <div class="icon icon-success">
                            <i class="nc-icon nc-ruler-pencil" aria-hidden="true"></i>
                        </div>
                        <div class="description">
                            <h4 class="info-title">For Designers Only</h4>
                            <p>The moment you use Paper Kit, you know you’ve never felt anything like it. With a single use, this powerfull UI Kit lets you do more than ever before.</p>
                        </div>
                    </div>
                    <div class="info info-horizontal">
                        <div class="icon icon-danger">
                            <i class="nc-icon nc-tie-bow" aria-hidden="true"></i>
                        </div>
                        <div class="description">
                            <h4 class="info-title">Beautiful Examples</h4>
                            <p>Speed up your development time or get inspired with the large number of example pages.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


<!--     *********     FEATURES 5      *********      -->

    <div class="features-5 section-image" style="background-image: url('assets/img/sections/the-how-photographer.jpg')">
        <div class="container">
            <div class="row">
                <div class="ml-auto mr-auto">
                    <h2 class="title text-center">Your life will be much easier</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5 ml-auto">
                    <div class="info">
                        <div class="icon">
                            <i class="nc-icon nc-atom" aria-hidden="true"></i>
                        </div>
                        <h4 class="title">For Developers</h4>
                        <p>While everyone technically has the feature now, that doesn’t mean you’ll see it all the time. But in this case, the feature wasn’t just randomly A/B tested.</p>
                    </div>
                </div>

                <div class="col-sm-5 mr-auto">
                    <div class="info">
                        <div class="icon">
                            <i class="nc-icon nc-ruler-pencil" aria-hidden="true"></i>
                        </div>
                        <h4 class="title">For Designers</h4>
                        <p>Loveflutter has actually been around for a while, pivoting from matches based on shared interests (innovative!) to now focus on analyzing.</p>
                    </div>
                </div>
            </div>

            <div class="row bottom-line">
                <div class="col-sm-5 ml-auto">
                    <div class="info">
                        <div class="icon">
                            <i class="nc-icon nc-paper" aria-hidden="true"></i>
                        </div>
                        <h4 class="title">Example Pages Included</h4>
                        <p>As for the user backlash, it comes to the challenges faces in building features to aid newcomers, which can impact its growth and bottom line.</p>
                    </div>
                </div>

                <div class="col-sm-5 mr-auto">
                    <div class="info">
                        <div class="icon">
                            <i class="nc-icon nc-time-alarm" aria-hidden="true"></i>
                        </div>
                        <h4 class="title">Save Time</h4>
                        <p>The feature itself had been developed via experimental account, where it had tested how to better personalize its service to end users.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
