<div class="wrapper">

    <div class="main">
        <div class="section section-white section-search">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-12 ml-auto mr-auto text-center">
                        <form role="search" class="form-inline search-form">
                            <div class="input-group no-border">
                              <span class="input-group-addon addon-xtreme no-border" id="basic-addon1"><i class="fa fa-search"></i></span>
                              <input type="text" class="form-control input-xtreme no-border" placeholder="Find Stuff" aria-describedby="basic-addon1">
                            </div>
                        </form>

                        <h6 class="text-muted">Is this what you are looking for?</h6>
                        <ul class="list-unstyled follows">
                            <li>
                                <div class="row">
                                    <div class="col-md-2 col-3">
                                        <img src="./assets/img/faces/ayo-ogunseinde-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                                    </div>
                                    <div class="col-md-6 col-4 description">
                                        <h5>Erik Faker<br>
                                        <small>Musical Artist with <b>3</b> songs.</small>
                                        </h5>
                                    </div>
                                    <div class="col-md-2 col-2">
                                        <button class="btn btn-just-icon btn-round btn-outline-danger btn-tooltip" placement="right" ngbTooltip="follow"><i class="fa fa-plus"></i></button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-md-2 col-3">
                                        <img src="./assets/img/faces/kaci-baum-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                                    </div>
                                    <div class="col-md-6 col-4 description">
                                        <h5>Sophie Travolta<br /><small>Singer</small></h5>
                                    </div>
                                    <div class="col-md-2 col-2">
                                        <button class="btn btn-just-icon btn-round btn-outline-danger btn-tooltip" placement="right" ngbTooltip="follow"><i class="fa fa-plus"></i></button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-md-2 col-3">
                                        <img src="./assets/img/faces/erik-lucatero-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                                    </div>
                                    <div class="col-md-6 col-4 description">
                                        <h5>John Lincoln<br /><small>Musical Producer</small></h5>
                                    </div>
                                    <div class="col-md-2 col-2">
                                        <button class="btn btn-just-icon btn-round btn-outline-danger btn-tooltip" placement="right" ngbTooltip="follow"><i class="fa fa-plus"></i></button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="row">
                                    <div class="col-md-2 col-3">
                                        <img src="./assets/img/faces/clem-onojeghuo-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                                    </div>
                                    <div class="col-md-6 col-4 description">
                                        <h5>Oleg Clem<br /><small>Web Designer</small></h5>
                                    </div>
                                    <div class="col-md-2 col-2">
                                        <button class="btn btn-just-icon btn-round btn-outline-danger btn-tooltip" placement="right" ngbTooltip="follow"><i class="fa fa-plus"></i></button>
                                    </div>
                                </div>
                            </li>

                        </ul>

                        <div class="text-missing">
                            <h5 class="text-muted">If you are not finding who you’re looking for try using an email address. </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<footer class="footer">
    <div class="container">
        <div class="row">
            <nav class="footer-nav">
                <ul>
                    <li><a href="https://www.creative-tim.com">Creative Tim</a></li>
                    <li><a href="http://blog.creative-tim.com">Blog</a></li>
                    <li><a href="https://www.creative-tim.com/license">Licenses</a></li>
                </ul>
            </nav>
            <div class="credits ml-auto">
                <span class="copyright">
                    © {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Creative Tim
                </span>
            </div>
        </div>
    </div>
</footer>
