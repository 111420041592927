<div class="container tim-container">

    <div id="info-areas">
        <div class="title">
            <h3>Info Areas</h3>
            <h4><small>Simple</small></h4>
        </div>

        <div class="row">
            <div class="col-md-4">
               <div class="info">
                    <div class="icon">
                        <i class="nc-icon nc-time-alarm"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title"> Save Time </h4>
                        <p>Spend your time generating new ideas. You don't have to think of implementing anymore.</p>
                    </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="info">
                    <div class="icon">
                        <i class="nc-icon nc-delivery-fast"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title"> Fast Prototyping </h4>
                        <p>Larger, yet dramatically thinner. More powerful, but remarkably power efficient.</p>
                    </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="info">
                    <div class="icon">
                        <i class="nc-icon nc-palette"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title"> Beautiful Colors </h4>
                        <p>Choose from a veriety of colors resembling sugar paper pastels.</p>
                    </div>
               </div>
            </div>
        </div>
        <h4><small>Coloured Icons</small></h4>
        <div class="row">
            <div class="col-md-4">
               <div class="info">
                    <div class="icon icon-primary">
                        <i class="nc-icon nc-album-2"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title"> Beautiful Gallery </h4>
                        <p>Spend your time generating new ideas. You don't have to think of implementing anymore.</p>
                    </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="info">
                    <div class="icon icon-warning">
                        <i class="nc-icon nc-bulb-63"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title"> New Ideas </h4>
                        <p>Larger, yet dramatically thinner. More powerful, but remarkably power efficient.</p>
                    </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="info">
                    <div class="icon icon-danger">
                        <i class="nc-icon nc-chart-bar-32"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title"> Statistics </h4>
                        <p>Choose from a veriety of colors resembling sugar paper pastels.</p>
                    </div>
               </div>
            </div>
        </div>
        <h4><small>Horizontal</small></h4>
        <div class="row">
            <div class="col-md-4">
               <div class="info info-horizontal">
                    <div class="icon">
                        <i class="nc-icon nc-camera-compact"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title"> Beautiful Gallery </h4>
                        <p>Spend your time generating new ideas. You don't have to think of implementing anymore.</p>
                        <a href="javascript:void(0)" class="btn btn-link btn-info">See more</a>
                    </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="info info-horizontal">
                    <div class="icon icon-warning">
                        <i class="nc-icon nc-glasses-2"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title"> New Ideas </h4>
                        <p>Larger, yet dramatically thinner. More powerful, but remarkably power efficient.</p>
                        <a href="javascript:void(0)" class="btn btn-link btn-info">See more</a>
                    </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="info info-horizontal">
                    <div class="icon icon-danger">
                        <i class="nc-icon nc-sound-wave"></i>
                    </div>
                    <div class="description">
                        <h4 class="info-title"> Statistics </h4>
                        <p>Choose from a veriety of colors resembling sugar paper pastels.</p>
                        <a href="javascript:void(0)" class="btn btn-link btn-info">See more</a>
                    </div>
               </div>
            </div>
        </div>
    </div>
</div>
