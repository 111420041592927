<div class="background-img" style="background-image: url('./assets/img/sections/jan-sendereks.jpg')">
    <div class="filter"></div>
    <div class="container">
        <div class="row">
            <h1 class="title"> &nbsp;422 <br/>
                <p class="error-msg">The changes you wanted was rejected</p>
            </h1>


        </div>

        <div class="container-cards">
            <div class="row">
                <h5 class="discover-pages text-center">You can discover:</h5>
                <br/><br/><br/>
            </div>

            <div class="row">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="card card-hover-effect card-just-text">
                                <div class="card-body text-center">
                                    <div class="card-icon"><a href="#paperkit"><i class="fa fa-desktop" aria-hidden="true"></i></a></div>
                                    <p class="card-description">Admin & Dashboards</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card card-hover-effect card-just-text">
                                <div class="card-body text-center">
                                    <h4 class="card-icon"><a href="#paperkit"><i class="fa fa-scissors" aria-hidden="true"></i></a></h4>
                                    <p class="card-description">UI Kits</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card card-hover-effect card-just-text">
                                <div class="card-body text-center">
                                    <h4 class="card-icon"><a href="#paperkit"><i class="fa fa-gift" aria-hidden="true"></i></a></h4>
                                    <p class="card-description">Free Themes</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card card-hover-effect card-just-text">
                                <div class="card-body text-center">
                                    <h4 class="card-icon"><a href="#paperkit"><i class="fa fa-puzzle-piece" aria-hidden="true"></i></a></h4>
                                    <p class="card-description">Bootstrap components</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
