
<div class="wrapper">
  <div class="page-header section-dark" style="background-image: url('./assets/img/sections/pk-pro-cover.jpg')">
    <div class="content-center">
      <div class="container">
        <div class="title-brand">
          <div class="angular-logo"><img alt="" src="./assets/img/angular2-logo.png"></div>
          <h1 class="presentation-title">Paper Kit 2</h1>
          <div class="type">PRO</div>
          <div class="fog-low">
            <img src="./assets/img/sections/fog-low.png" alt="">
          </div>
          <div class="fog-low right">
            <img src="./assets/img/sections/fog-low.png" alt="">
          </div>
        </div>
        <h2 class="presentation-subtitle text-center">Make your mark with a premium Bootstrap 4 UI Kit! </h2>
      </div>
    </div>
    <h6 class="category category-absolute">Designed and coded by
      <a href="https://www.creative-tim.com" target="_blank">
        <img src="./assets/img/creative-tim-white-slim2.png" class="creative-tim-logo">
      </a>
    </h6>
  </div>
</div>
<div class="section section-dark section-summary">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="info">
          <div class="icon icon-danger">
            <i class="nc-icon nc-layout-11"></i>
          </div>
          <div class="description">
            <h4 class="info-title"> Huge Number of Components </h4>
            <p>The kit comes with components designed to look perfect together. All components fit perfectly with each other.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="info">
          <div class="icon icon-danger">
            <i class="nc-icon nc-tile-56"></i>
          </div>
          <div class="description">
            <h4 class="info-title"> Multi-Purpose Sections </h4>
            <p>The sections will help you generate pages in no time. From team presentation to pricing options, you can easily customise and built your examples.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="info">
          <div class="icon icon-danger">
            <i class="nc-icon nc-paper"></i>
          </div>
          <div class="description">
            <h4 class="info-title"> Example Pages </h4>
            <p>Speed up your development time or get inspired with the large number of example pages. You can jump start your development with our pre-built example pages.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section section-components section-dark">
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <div class="image-container">
        <img class="components-macbook" src="./assets/img/presentation-page/laptop-basic.png" alt="" />
        <img class="table-img" src="./assets/img/presentation-page/table.jpg" alt="" />
        <img class="share-btn-img" src="./assets/img/presentation-page/share-btn.png" alt="" />
        <img class="coloured-card-btn-img" src="./assets/img/presentation-page/coloured-card-with-btn.png" alt="" />
        <img class="coloured-card-img" src="./assets/img/presentation-page/coloured-card.png" alt="" />
        <img class="social-img" src="./assets/img/presentation-page/social-row.png" alt="" />
        <img class="pin-btn-img" src="./assets/img/presentation-page/pin-btn.png" alt="" />
      </div>
    </div>
    <div class="col-md-10 col-lg-4 ml-auto mr-auto">
      <div class="container basic-container">
        <h3 class="title">Basic Components</h3>
        <h6 class="category">The core elements of your website</h6>
        <h5 class="description">We re-styled every Bootstrap 4 element to match the Paper Kit style. All the Bootstrap 4 components that you need in a development have been re-design with the new look. Besides the numerous basic elements, we have also created additional classes. All these items will help you take your project to the next level.</h5>
      </div>
    </div>
  </div>
</div>
<div class="section section-cards section-gold">
  <div class="codrops-header">
    <div class="row">
      <div class="col-md-5 col-lg-4">
        <div class="section-description">
          <h3 class="title">Beautiful Cards</h3>
          <h6 class="category">One Card for Every Problem</h6>
          <h5 class="description">We have gone above and beyond with options for you to organise your information. From cards designed for blog posts, to product cards or user profiles, you will have many options to choose from. All the cards follow the Paper Kit style principles and have a design that stands out.  </h5>
        </div>
      </div>
        <div class="col-md-7 col-lg-8">
            <div class="image-container">
                <img src="./assets/img/carduri2.png" alt="Cards">
            </div>
        </div>
    </div>
  </div>
</div>
<div id="fadeInAnim">
  <div class="section section-content section-gray">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="image-container">
            <img class="img" src="./assets/img/presentation-page/iPad-content-2.png" />
            <img class="area-img add-animation" src="./assets/img/presentation-page/ipad-left-img.JPG" />
            <img class="info-img add-animation" src="./assets/img/presentation-page/ipad-right-img.JPG" />
          </div>
        </div>

        <div class="col-md-4 ">
          <div class="section-description">
            <h3 class="title">Content Areas</h3>
            <h6 class="category">For Areas that Need More Space</h6>
            <h5 class="description">We took into consideration multiple use cases and came up with some specific elements for you. If you need elements such as tables, comments, description areas, tabs and many others, we've got you covered. They're beautiful and easy to use for the end user navigating your website. </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section section-sections section-gray">
  <div class="container">
    <div class="col-md-8 ml-auto mr-auto">
      <div class="section-description text-center">
        <h2 class="title">Sections you will love</h2>

        <h5 class="description"> Build pages in no time using pre-made sections! We have created multiple sections for you to put together and customise into pixel perfect example pages. From headers to footers, you will be able to choose the best combination for your project.</h5>
        <br/>
        <a [routerLink]="['/sections']" class="btn btn-rose btn-round">View All Sections</a>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="section-cols">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/header-1.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/header-2.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/header-3.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/header-4.jpg" alt="" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/team-cards-social.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/bg-pricing-card.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/white-pricing-card.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/team-member-card.jpg" alt="" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/testimonials-1.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/blog-bg-cards.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/our-products.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/projects-cards.jpg" alt="" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/blogpost-cards.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/blogpost-2-cards.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/coloured-picind-card.jpg" alt="" />
          </div>
          <div class="col-md-3">
            <img src="./assets/img/presentation-page/screenshots/team-3.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section section-examples section-dark" id="examples">
  <div class="container">
    <div class="row">
      <div class="title text-center">
                <h2 class="title">Ready to use Page Examples</h2>
        <h5 class="description">Paper Kit 2 Pro comes with a large number of example pages, that give you the option to quickly setup the basic flows for your website. From registering to building your profile or setting a blog, we've got you covered.</h5>
            </div>
    </div>
    <div class="row">
      <div class="col-md-4">
                  <div class="details-text">
          Twitter Redesign
        </div>
        <div class="card card-image card-hover-effect">
          <a target="_blank" [routerLink]="['/examples/twitter']">
            <img src="./assets/img/presentation-page/examples/twitter.png">
          </a>
        </div>

        <div class="details-text">
          Error Page (422)
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/page422']">
            <img src="./assets/img/presentation-page/examples/422.png">
                      </a>
        </div>

        <div class="details-text">
          Discover Page
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/discover']">
            <img src="./assets/img/presentation-page/examples/discover.png">
          </a>
        </div>

        <div class="details-text">
          Login Page
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/login']">
            <img src="./assets/img/presentation-page/examples/login-page.png">
          </a>
        </div>

        <div class="details-text">
          Blog Post
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/blogpost']">
            <img src="./assets/img/presentation-page/examples/blog-post.png">
                      </a>
        </div>

        <div class="details-text">
          User Settings
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/settings']">
            <img src="./assets/img/presentation-page/examples/settings.png">
          </a>
        </div>
      </div>

      <div class="col-md-4">
        <div class="details-text">
          Register Page
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/register']">
            <img src="./assets/img/presentation-page/examples/register.png">
          </a>
        </div>

        <div class="details-text">
          Public Profile
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/profile']">
            <img src="./assets/img/presentation-page/examples/profile.png">
                      </a>
        </div>
        <div class="details-text">
          Product Page
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/productpage']">
            <img src="./assets/img/presentation-page/examples/product.png">
          </a>
        </div>

        <div class="details-text">
          Contact Us
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/contactus']">
            <img src="./assets/img/presentation-page/examples/contact-us.png">
          </a>
        </div>

        <div class="details-text">
          Search Page
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/search']">
            <img src="./assets/img/presentation-page/examples/search.png">
          </a>
        </div>

        <div class="details-text">
          Error Page (400)
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/page404']">
            <img src="./assets/img/presentation-page/examples/404.png">
          </a>
        </div>

        <div class="details-text">
          Add Product Page
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/addproduct']">
            <img src="./assets/img/presentation-page/examples/add-product.png">
          </a>
        </div>


        <div class="details-text">
          About Us
        </div>
        <div class="card card-image card-hover-effect">
          <a target="_blank" [routerLink]="['/examples/aboutus']">
            <img src="./assets/img/presentation-page/examples/about-us.png">
          </a>
        </div>

      </div>

      <div class="col-md-4">
        <div class="details-text">
          Blog Posts
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/blogposts']">
            <img src="./assets/img/presentation-page/examples/blog-posts.png">
          </a>
        </div>

        <div class="details-text">
          Error Page (500)
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/page500']">
            <img src="./assets/img/presentation-page/examples/500.png">
                      </a>
        </div>

        <div class="details-text">
          Landing Page
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/landing']">
            <img src="./assets/img/presentation-page/examples/landing.png" />
          </a>
        </div>

        <div class="details-text">
          Ecommerce Page
        </div>
        <div class="card card-image">
          <a target="_blank" [routerLink]="['/examples/ecommerce']">
            <img src="./assets/img/presentation-page/examples/ecommerce.png">
          </a>
        </div>


        <div class="card card-image">
          <img src="./assets/img/presentation-page/examples/more-brown.png">
        </div>

      </div>
    </div>
  </div>
</div>
<div class="section section-icons">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <div class="icons-nucleo">
          <i class="first-left-icon nc-icon nc-planet add-animation" ></i>
          <i class="second-left-icon nc-icon nc-touch-id add-animation"></i>
          <i class="third-left-icon nc-icon nc-lock-circle-open add-animation"></i>
          <i class="fourth-left-icon nc-icon nc-tap-01 add-animation"></i>
          <i class="fifth-left-icon nc-icon nc-zoom-split add-animation"></i>
          <i class="sixth-left-icon nc-icon nc-atom add-animation"></i>
          <i class="seventh-left-icon nc-icon nc-favourite-28 add-animation"></i>
          <i class="eighth-left-icon nc-icon nc-settings add-animation"></i>
          <i class="ninth-left-icon nc-icon nc-bulb-63 add-animation"></i>
          <i class="tenth-left-icon nc-icon nc-circle-10 add-animation"></i>
        </div>
      </div>
      <div class="col-md-6 text-center">
                <h2 class="title revealOnScroll">Custom Icons</h2>
                <h5 class="description">
                    Paper Kit 2 PRO comes with 100 custom demo icons made by our friends from <a href="https://nucleoapp.com/?ref=1712" target="_blank">NucleoApp</a>. The official package contains over 20.729 icons which are looking great in combination with Paper Kit 2 PRO. Make sure you check all of them and use those that you like the most.
                </h5>
        <br/>
                <a [routerLink]="['/nucleoicons']" class="btn btn-primary btn-round btn-lg " target="_blank">View Demo Icons</a>
                <a href="https://nucleoapp.com/?ref=1712" class="btn btn-outline-primary btn-round btn-lg " target="_blank">View All Icons</a>
      </div>
      <div class="col-md-3">
        <div class="icons-nucleo">
          <i class="first-right-icon nc-icon nc-bank add-animation"></i>
          <i class="second-right-icon nc-icon nc-tag-content add-animation"></i>
          <i class="third-right-icon nc-icon nc-bell-55 add-animation"></i>
          <i class="fourth-right-icon nc-icon nc-tv-2 add-animation"></i>
          <i class="fifth-right-icon nc-icon nc-map-big add-animation"></i>
          <i class="sixth-right-icon nc-icon nc-badge add-animation"></i>
          <i class="seventh-right-icon nc-icon nc-simple-add add-animation"></i>
          <i class="eighth-right-icon nc-icon nc-trophy add-animation"></i>
          <i class="ninth-right-icon nc-icon nc-hat-3 add-animation"></i>
          <i class="tenth-right-icon nc-icon nc-key-25 add-animation"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="section section-free-demo section-dark" style="display: none">
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <div class="icon-github">
          <i class="fa fa-github"></i>
        </div>
        <div class="section-description">
          <h3 class="title">Free Demo</h3>
          <h5 class="description">Do you want to test and see the benefits of this kit before purchasing it? You can give the demo version a try. It features enough basic components for you to get a feel of the design and also test the quality of the code. Get it free on GitHub!</h5>
          <div class="row space-top">
            <div class="col-md-6">
              <a href="https://github.com/creativetimofficial/paper-kit/tree/bootstrap4-development" target="_blank" class="btn btn-round btn-neutral">View Demo on Github</a>
            </div>
            <div class="col-md-6">
              <a class="github-button" href="https://github.com/creativetimofficial/paper-kit" data-show-count="true" data-size="large" aria-label="Star ntkme/github-buttons on GitHub">Star</a>

              <a class="github-button" href="https://github.com/creativetimofficial/paper-kit" data-icon="octicon-repo-forked" data-size="large" aria-label="Fork ntkme/github-buttons on GitHub">Fork</a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 offset-md-1">
        <div class="row">
          <div class="col-md-6">
            <div class="card card-pricing">
              <div class="card-body">
                <h3 class="card-title">Free Demo</h3>
                <ul>
                  <li><b>60</b> Components*</li>
                  <li><b>3</b> Example Pages</li>
                  <li><b>0</b> Customised Plugins</li>
                  <li><i class="fa fa-times text-danger"></i> Customised Icons </li>
                  <li><i class="fa fa-times text-danger"></i> Beautiful Cards</li>
                  <li><i class="fa fa-times text-danger"></i> Sections</li>
                  <li><i class="fa fa-times text-danger"></i> Premium Support</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card card-pricing">
              <div class="card-body">
                <h3 class="card-title">PRO Version</h3>
                <ul>
                  <li> <b>1000+</b> Components*</li>
                  <li> <b>15</b> Example Pages</li>
                  <li> <b>8</b> Customised Plugins</li>
                  <li><i class="fa fa-check text-success"></i> Customised Icons </li>
                  <li><i class="fa fa-check text-success"></i> Beautiful Cards</li>
                  <li><i class="fa fa-check text-success"></i> Sections</li>
                  <li><i class="fa fa-check text-success"></i> Premium Support</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <br>
            <p class="description">*Components: buttons, social buttons, navbars, dropdowns, tabs, tables, cards, select, tags, sliders etc. and their customisation by classes.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="section section-responsive section-gold">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="phone-container">
          <img src="./assets/img/presentation-page/responsive.png" />
        </div>
      </div>
      <div class="col-lg-4">

                 <div class="info info-horizontal">
                      <div class="icon icon-danger">
            <i class="nc-icon nc-tablet-2"></i>
                      </div>
                      <div class="description">
                          <h4 class="info-title"> Fully Responsive Layout </h4>
                          <p> The kit is designed to be mobile first. Every components looks great on any screen size.</p>
                      </div>
                 </div>

         <div class="info info-horizontal">
          <div class="icon icon-danger">
            <i class="nc-icon nc-html5"></i>
          </div>
          <div class="description">
            <h4 class="info-title"> HTML5 Powered by Bootstrap 4 </h4>
            <p>Paper Kit 2 Pro is built on top of Bootstrap 4. Get the benefits of the most powerful framework and the perks of amazing design.</p>
          </div>
         </div>


      </div>
    </div>
  </div>
</div>

<div class="section section-overview">
  <div class="container-fluid">
    <div class="row">
        <div class="col-md-8 offset-md-2 text-center">
                <div class="space-top"></div>
          <h2 class="title">Want more details?</h2>
        </div>
        <div class="container">
        <div class="space-top"></div>
          <div class="row">

          <div class="col-sm-3">
            <div class="card" data-background="color" data-color="green">
              <div class="card-body text-center">
                <div class="card-icon">
                  <i class="nc-icon nc-money-coins"></i>
                </div>
                <h4 class="card-title">Save Money</h4>
                <p class="card-description">Creating your design from scratch with dedicated designers can be very expensive. Using a kit is the best option to start your development.</p>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="card" data-background="color" data-color="blue">
              <div class="card-body text-center">
                <div class="card-icon">
                  <i class="nc-icon nc-watch-time"></i>
                </div>
                <h4 class="card-title">Save Time</h4>
                <p class="card-description">Using the kit will save you large amount of time. You don't have to worry about customising the basic Bootstrap 4 design or its components.</p>
              </div>
            </div>
          </div>

            <div class="col-sm-3">
            <div class="card" data-background="color" data-color="purple">
              <div class="card-body text-center">
                                <div class="card-icon">
                  <i class="nc-icon nc-layout-11"></i>
                </div>
                <h4 class="card-title">Bootstrap 4 Grid</h4>
                                <p class="card-description">Enjoy the fluid grid system based on 12 columns. Paper Kit 2 PRO is built over Bootstrap 4 and has all the benefits that the framework comes with.</p>
              </div>
            </div>
            </div>

            <div class="col-sm-3">
            <div class="card" data-background="color" data-color="brown">
              <div class="card-body text-center">
                                <div class="card-icon">
                  <i class="nc-icon nc-align-center"></i>
                </div>
                <h4 class="card-title">Built with Flexbox</h4>
                                <p class="card-description">Paper Kit 2 PRO primarily uses the flexbox for the layout, grid system, cards and components. This is a huge advantage for working with columns. </p>
              </div>
            </div>
            </div>

          </div>
        </div>
        </div>
  </div>
</div>

<div class="section section-testimonials">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 ml-auto mr-auto text-center">
        <h2 class="title">Trusted by 232,000+ People</h2>
        <h5 class="description">The UI Kits, Templates and Dashboards that we've created are used by <b>232,000+ web developers</b> in over <b>400,000 Web Projects</b>. This is what some of them think:</h5>
      </div>
    </div>

    <div class="row">

      <div class="col-md-2 ml-auto">
        <div class="testimonials-people">
          <img class="left-first-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/110862/thumb.?1482812727" alt="" />
          <img class="left-second-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/139481/thumb.jpg?1485460613" alt="" />
          <img class="left-third-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/152441/thumb.jpg?1488233314" alt="" />
          <img class="left-fourth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/134607/thumb.?1487680276" alt="" />
          <img class="left-fifth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/161506/thumb.?1489848178" alt="" />
          <img class="left-sixth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/77627/thumb.jpg?1487360092" alt="" />
        </div>
      </div>

      <div class="col-md-6">

        <div class="page-carousel">
                      <ngb-carousel [interval]="1500">
                          <ng-template ngbSlide class="carousel-item">
                              <div class="card card-testimonial card-plain">
                <div class="card-avatar">
                  <img class="img" src="https://s3.amazonaws.com/creativetim_bucket/photos/123334/thumb.JPG?1479459618" />
                </div>
                <div class="card-body">
                  <h5 class="card-description">
                  "I'm newer to the front-end... With my creative side lacking in experience this really put me in the right spot to speed through the fast lane. I love this Design kit so much!"
                  </h5>
                  <div class="card-footer">
                    <h4 class="card-title">Chase Jackson</h4>
                    <h6 class="card-category">Web Developer</h6>
                    <div class="card-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

              </div>
                          </ng-template>
                          <ng-template ngbSlide class="carousel-item">
                              <div class="card card-testimonial card-plain">
                <div class="card-avatar">
                  <img class="img" src="https://s3.amazonaws.com/creativetim_bucket/photos/134607/thumb.?1487680276" />
                </div>
                <div class="card-body">
                  <h5 class="card-description">
                  "Love the shapes and color palette on this one! Perfect for one of my pet projects!"
                  </h5>
                  <div class="card-footer">
                    <h4 class="card-title">Robin Leysen</h4>
                    <h6 class="card-category">Web Developer</h6>
                    <div class="card-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

              </div>
                          </ng-template>
                          <ng-template ngbSlide class="carousel-item">
                              <div class="card card-testimonial card-plain">
                <div class="card-avatar">
                  <img class="img" src="https://s3.amazonaws.com/creativetim_bucket/photos/125268/thumb.jpeg?1497799215" />
                </div>
                <div class="card-body">
                  <h5 class="card-description">
                  "Love it. Use it for prototypes and along with Paper Dashboard."
                  </h5>
                  <div class="card-footer">
                    <h4 class="card-title">Cristi Jora</h4>
                    <h6 class="card-category">Web Developer</h6>
                    <div class="card-stars">
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

              </div>
                          </ng-template>
                      </ngb-carousel>
                </div>
      </div>

      <div class="col-md-2 mr-auto">
        <div class="testimonials-people">
          <img class="right-first-person add-animation" src="./assets/img/faces/clem-onojeghuo-3.jpg" alt="" />
          <img class="right-second-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/125268/thumb.jpeg?1497799215" alt="" />
          <img class="right-third-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/123334/thumb.JPG?1479459618" alt="" />
          <img class="right-fourth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/118235/thumb.?1477435947" alt="" />
          <img class="right-fifth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/18/thumb.png?1431433244" alt="" />
          <img class="right-sixth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/167683/thumb.?1491014996" alt="" />
        </div>
      </div>

    </div>
  </div>

  <div class="our-clients">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-2 col-5">
          <img src="./assets/img/demo/vodafone.jpg" alt="" />
        </div>
        <div class="col-md-2 col-5">
          <img src="./assets/img/demo/microsoft.jpg" alt="" />
        </div>
        <div class="col-md-2 col-5">
          <img src="./assets/img/demo/harvard.jpg" alt="" />
        </div>
        <div class="col-md-2 col-5">
          <img src="./assets/img/demo/stanford.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="section section-sharing section-dark" id="demoPay">
  <div class="container">
    <div class="row">
      <div class="title text-center">
                <h3 class="title">Ready to grab this Powerful UI Kit?</h3>
        <p class="description">You have <b>Free Unlimited Updates</b> and <b>Premium Support</b> on each package. You also have <b>30 days</b> to request a refund if you're not happy with your purchase.</p>
        <br/>
      </div>

      <div class="col-md-8 ml-auto mr-auto">
        <div class="space-top"></div>
        <div class="row">
          <div class="col-md-6">
            <div class="card card-pricing">
              <div class="card-body">
                <div class="card-icon">
                  <span class="icon-simple">
                    <i class="nc-icon nc-single-02"></i>
                  </span>
                </div>
                <h3 class="card-title">$59</h3>

                <p class="card-description">
                  For personal websites, school projects, startups or any other project where your users will not be charged for using it. Create one single website/app for one client.
                </p>
                <div class="card-footer">
                  <a href="https://www.creative-tim.com/product/paper-kit-2-pro-angular" class="btn btn-danger btn-round">Buy Now</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card card-pricing">
              <div class="card-body">
                <div class="card-icon">
                  <span class="icon-simple">
                    <i class="nc-icon nc-bank"></i>
                  </span>
                </div>
                <h3 class="card-title">$239</h3>

                <p class="card-description">
                  For business projects, multiple websites for clients or for creating themes where your users will be charged for using it. Create multiple websites/apps for multiple clients.
                </p>
                <div class="card-footer">
                  <a href="https://www.creative-tim.com/product/paper-kit-2-pro-angular" class="btn btn-danger btn-round">Buy Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-8 offset-md-2 text-center">

      </div>

    </div>

    <div class="text-center">
        <span>Pay one time with:</span>
        <i class="fa fa-cc-paypal fa-pay"></i>
        <i class="fa fa-cc-visa fa-pay"></i>
        <i class="fa fa-cc-mastercard fa-pay"></i>
        <i class="fa fa-cc-amex fa-pay"></i>
        <br><br>
    </div>

    <div class="title text-center">
      <h3>Thank you for sharing!</h3>

      <!-- <br> -->
      <!-- <button id="twitter" class="btn btn-round btn-twitter"><i class="fa fa-twitter"></i> Twitter </button>
      <button id="facebook" class="btn btn-round btn-facebook"><i class="fa fa-facebook-square"></i> Facebook</button>
      <button id="google" class="btn btn-round btn-google"><i class="fa fa-google-plus"></i> Google</button> -->

    </div>
      </div>
</div>
<footer class="footer footer-black">
<div class="container">
  <div class="row">
    <nav class="footer-nav">
      <ul>
        <li><a href="https://www.creative-tim.com">Creative Tim</a></li>
        <li><a href="https://blog.creative-tim.com">Blog</a></li>
        <li><a href="https://www.creative-tim.com/license">Licenses</a></li>
      </ul>
    </nav>
    <div class="credits ml-auto">
      <span class="copyright">
        © {{date | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Creative Tim
      </span>
    </div>
  </div>
</div>
</footer>
