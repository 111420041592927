<div class="wrapper">
    <div class="page-header page-header-small"  style="background-image: url('./assets/img/rihanna_cover.jpg');">
        <div class="filter"></div>
    </div>
    <div class="profile-content section-white-gray">
        <div class="container">
            <div class="row owner">
                <div class="col-md-2 col-sm-4 col-6 ml-auto mr-auto text-center">
                    <div class="avatar">
                        <img src="./assets/img/rihanna.jpg" alt="Circle Image" class="img-circle img-responsive">
                        <div class="following">
                            <button class="btn btn-sm btn-info btn-just-icon" rel="tooltip" title="Follow"><i class="nc-icon nc-simple-add"></i></button>
                        </div>
                    </div>

                    <div class="name">
                        <h4>Rihanna <br /><small>@rihanna</small></h4>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                    <p>Excited to share this with you! <a class="link-danger twitter-hashtag" href="javascript: void(0);">#ANTIdiaRy</a> — <a href="javascript: void(0);">http://smarturl.it</a> </p>
                    <div class="description-details">
                        <ul class="list-unstyled">
                            <li><i class="fa fa-map-marker"></i> ANTI</li>
                            <li><i class="fa fa-link"></i> <a href="javascript:void(0);">rihanna.com</a></li>
                            <li><i class="fa fa-calendar"></i> Joined October 2009</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="profile-tabs">
                <div class="nav-tabs-navigation">
                    <div class="nav-tabs-wrapper">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-tabs justify-content-center">
                            <li class="nav-item" [ngbNavItem]="1">
                                <a class="nav-link" ngbNavLink> Tweets</a>
                                <ng-template ngbNavContent>
                                    <div class="tab-pane active" id="tweets" role="tabpanel">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="tweets">
                                                    <div class="media">
                                                          <a class="pull-left" href="javascript:void(0)">
                                                           <div class="avatar">
                                                                <img class="media-object" src="./assets/img/rihanna.jpg" alt="..." />
                                                            </div>
                                                          </a>
                                                          <div class="media-body">
                                                            <strong>Rihanna</strong>
                                                            <h5 class="media-heading"><small>@rihanna &middot; 1h</small></h5>
                                                            <p>It's just beyond the vault. Discover room 7 of the <a href="javascript: void(0);" class="link-danger">#ANTIdiaRy</a> at <a href="javascript:void(0)" class="link-info">http://smarturl.it/AntidiaRyTW</a></p>

                                                            <div class="media-footer">
                                                                <a href="javascript:void(0)" class="btn btn-link">
                                                                     <i class="fa fa-reply"></i>
                                                                </a>
                                                                <a href="javascript:void(0)" class="btn btn-success btn-link">
                                                                     <i class="fa fa-retweet"></i> 2.1k
                                                                </a>
                                                                <a href="javascript:void(0)" class="btn btn-danger btn-link">
                                                                     <i class="fa fa-heart"></i> 3.2k
                                                                </a>
                                                                <div class="dropdown">
                                                                  <button id="dLabel" type="button" class="btn btn-just-icon btn-link btn-lg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fa fa-ellipsis-h"></i>
                                                                  </button>
                                                                  <ul class="dropdown-menu dropdown-menu-right">
                                                                        <li class="dropdown-item">
                                                                            <a href="javascript:void(0)">
                                                                                <div class="row">
                                                                                    <div class="col-sm-2">
                                                                                        <span class="icon-simple"><i class="fa fa-envelope"></i></span>
                                                                                    </div>
                                                                                    <div class="col-sm-9">Direct Message</div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                        <div class="dropdown-divider"></div>
                                                                        <li class="dropdown-item">
                                                                            <a href="javascript:void(0)">
                                                                                <div class="row">
                                                                                    <div class="col-sm-2">
                                                                                        <span class="icon-simple"><i class="fa fa-microphone-slash"></i></span>
                                                                                    </div>
                                                                                    <div class="col-sm-9">Mute</div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                        <div class="dropdown-divider"></div>
                                                                        <li class="dropdown-item">
                                                                            <a href="javascript:void(0)">
                                                                                <div class="row">
                                                                                    <div class="col-sm-2">
                                                                                        <span class="icon-simple"><i class="fa fa-exclamation-circle"></i></span>
                                                                                    </div>
                                                                                    <div class="col-sm-9">Report</div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                          </div>
                                                    </div> <!-- end media -->

                                                    <div class="media">
                                                      <a class="pull-left" href="javascript:void(0)">
                                                          <div class="avatar">
                                                             <img class="media-object" alt="Tim Picture" src="./assets/img/khaled.jpg">
                                                          </div>
                                                          <div class="retweet">
                                                                <button class="btn btn-xs btn-success btn-just-icon btn-sm" rel="tooltip" title="Follow"><i class="fa fa-retweet"></i></button>
                                                          </div>
                                                      </a>
                                                      <div class="media-body">
                                                        <strong>DJ KHALED</strong>
                                                        <h5 class="media-heading"><small>@djkhaled &middot;  6 Jan 2016</small></h5>
                                                         <p><a href="javascript:void(0)" class="link-danger">#LA</a> fan luv I'm be <a href="javascript:void(0)" class="link-info">@1oakla</a> tonight i want see fan luv let's win more ! <a href="javascript:void(0)" class="link-danger">#wethebest</a> </p>
                                                        <div class="tweet-link">
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    <img src="./assets/img/khaled_tweet.png" alt="Rounded Image" class="img-rounded img-tweet-link img-responsive">
                                                                </div>
                                                                <div class="col-md-8">
                                                                    <strong>Let's win more by DJ Khaled</strong>
                                                                    <a href="javascript:void(0)"><p>This is a 3-day event hosted by DJ Khaled for his fan luv in LA. Major 🔑 to success. Bless up!</p>
                                                                    <small><a href="javascript:void(0)" class="text-muted">djkhaled.com</a></small></a>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div class="media-footer">
                                                            <a href="javascript:void(0)" class="btn btn-link">
                                                                 <i class="fa fa-reply"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="btn btn-link">
                                                                 <i class="fa fa-retweet"></i> 100
                                                            </a>
                                                            <a href="javascript:void(0)" class="btn btn-danger btn-link">
                                                                 <i class="fa fa-heart"></i> 234
                                                            </a>
                                                            <div class="dropdown">
                                                                  <button id="dLabel" type="button" class="btn btn-icon btn-link btn-lg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fa fa-ellipsis-h"></i>
                                                                  </button>
                                                                  <ul class="dropdown-menu dropdown-menu-right">
                                                                      <li class="dropdown-item">
                                                                          <a href="javascript:void(0)">
                                                                              <div class="row">
                                                                                  <div class="col-sm-2">
                                                                                      <span class="icon-simple"><i class="fa fa-envelope"></i></span>
                                                                                  </div>
                                                                                  <div class="col-sm-9">Direct Message</div>
                                                                              </div>
                                                                          </a>
                                                                      </li>
                                                                      <div class="dropdown-divider"></div>
                                                                      <li class="dropdown-item">
                                                                          <a href="javascript:void(0)">
                                                                              <div class="row">
                                                                                  <div class="col-sm-2">
                                                                                      <span class="icon-simple"><i class="fa fa-microphone-slash"></i></span>
                                                                                  </div>
                                                                                  <div class="col-sm-9">Mute</div>
                                                                              </div>
                                                                          </a>
                                                                      </li>
                                                                      <div class="dropdown-divider"></div>
                                                                      <li class="dropdown-item">
                                                                          <a href="javascript:void(0)">
                                                                              <div class="row">
                                                                                  <div class="col-sm-2">
                                                                                      <span class="icon-simple"><i class="fa fa-exclamation-circle"></i></span>
                                                                                  </div>
                                                                                  <div class="col-sm-9">Report</div>
                                                                              </div>
                                                                          </a>
                                                                      </li>
                                                                  </ul>
                                                                </div>
                                                        </div>
                                                      </div>
                                                    </div> <!-- end media -->
                                                    <div class="media">
                                                      <a class="pull-left" href="javascript:void(0)">
                                                          <div class="avatar">
                                                             <img class="media-object" alt="Tim Picture" src="./assets/img/rihanna.jpg">
                                                          </div>
                                                      </a>
                                                      <div class="media-body">
                                                        <strong>Rihanna</strong>
                                                        <h5 class="media-heading"><small>@rihanna &middot;  8 Jan 2016</small></h5>
                                                         <p>bitch better have my mistletoe <a href="javascript:void(0)" class="link-danger">#rihannaxstance</a> </p>
                                                         <img src="./assets/img/rihanna_tweet.jpeg" alt="Rounded Image" class="img-rounded img-tweet">


                                                        <div class="media-footer">
                                                            <a href="javascript:void(0)" class="btn btn-link">
                                                                 <i class="fa fa-reply"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="btn btn-link">
                                                                 <i class="fa fa-retweet"></i> 5.8K
                                                            </a>
                                                            <a href="javascript:void(0)" class="btn btn-danger btn-link">
                                                                 <i class="fa fa-heart"></i> 12K
                                                            </a>
                                                            <a href="javascript:void(0)" class="btn btn-link">
                                                                 <i class="fa fa-ellipsis-h"></i>
                                                            </a>
                                                        </div>
                                                      </div>
                                                    </div> <!-- end media -->
                                                    <div class="media">
                                                      <a class="pull-left" href="javascript:void(0)">
                                                          <div class="avatar">
                                                             <img class="media-object" alt="Tim Picture" src="./assets/img/rihanna.jpg">
                                                          </div>
                                                      </a>
                                                      <div class="media-body">
                                                        <strong>Rihanna</strong>
                                                        <h5 class="media-heading"><small>@rihanna &middot;  9 Jan 2016</small></h5>
                                                         <p>Thank you God for fulfilling Your plans in my life.... All the Glory belongs to You!!!! <a href="https://instagram.com/p/4m5W4sBMzj/">https://instagram.com/p/4m5W4sBMzj/</a> </p>


                                                        <div class="media-footer">
                                                            <a href="javascript:void(0)" class="btn btn-link">
                                                                 <i class="fa fa-reply"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="btn btn-link">
                                                                 <i class="fa fa-retweet"></i> 5.9K
                                                            </a>
                                                            <a href="javascript:void(0)" class="btn btn-link">
                                                                 <i class="fa fa-heart"></i> 11K
                                                            </a>
                                                            <div class="dropdown">
                                                                  <button id="dLabel" type="button" class="btn btn-icon btn-link btn-lg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fa fa-ellipsis-h"></i>
                                                                  </button>
                                                                  <ul class="dropdown-menu dropdown-menu-right">
                                                                        <li class="dropdown-item">
                                                                            <a href="javascript:void(0)">
                                                                                <div class="row">
                                                                                    <div class="col-sm-2">
                                                                                        <span class="icon-simple"><i class="fa fa-envelope"></i></span>
                                                                                    </div>
                                                                                    <div class="col-sm-9">Direct Message</div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                        <div class="dropdown-divider"></div>
                                                                        <li class="dropdown-item">
                                                                            <a href="javascript:void(0)">
                                                                                <div class="row">
                                                                                    <div class="col-sm-2">
                                                                                        <span class="icon-simple"><i class="fa fa-microphone-slash"></i></span>
                                                                                    </div>
                                                                                    <div class="col-sm-9">Mute</div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                        <div class="dropdown-divider"></div>
                                                                        <li class="dropdown-item">
                                                                            <a href="javascript:void(0)">
                                                                                <div class="row">
                                                                                    <div class="col-sm-2">
                                                                                        <span class="icon-simple"><i class="fa fa-exclamation-circle"></i></span>
                                                                                    </div>
                                                                                    <div class="col-sm-9">Report</div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                        </div>
                                                      </div>
                                                    </div> <!-- end media -->
                                                    <div class="media last-media">
                                                      <a class="pull-left" href="javascript:void(0)">
                                                          <div class="avatar">
                                                             <img class="media-object" alt="Tim Picture" src="./assets/img/billboard.jpeg">
                                                          </div>
                                                          <div class="retweet">
                                                                <button class="btn btn-xs btn-success btn-just-icon btn-sm" rel="tooltip" title="Follow"><i class="fa fa-retweet"></i></button>
                                                          </div>
                                                      </a>
                                                      <div class="media-body">
                                                        <strong>billboard</strong>
                                                        <h5 class="media-heading"><small>@billboard &middot;  1 Jul 2016</small></h5>
                                                         <p><a href="javascript:void(0)">@Rihanna</a> has become the first artist to surpass RIAA's 100 million cumulative singles award threshold: <a href="http://blbrd.cm/3rQ3Iq">http://blbrd.cm/3rQ3Iq</a> </p>

                                                        <div class="media-footer">
                                                            <a href="javascript:void(0)" class="btn btn-link">
                                                                 <i class="fa fa-reply"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" class="btn btn-link">
                                                                 <i class="fa fa-retweet"></i> 5.6K
                                                            </a>
                                                            <a href="javascript:void(0)" class="btn btn-danger btn-link">
                                                                 <i class="fa fa-heart"></i> 7.2K
                                                            </a>
                                                            <div class="dropdown">
                                                                  <button id="dLabel" type="button" class="btn btn-icon btn-link btn-lg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fa fa-ellipsis-h"></i>
                                                                  </button>
                                                                  <ul class="dropdown-menu dropdown-menu-right">
                                                                        <li class="dropdown-item">
                                                                            <a href="javascript:void(0)">
                                                                                <div class="row">
                                                                                    <div class="col-sm-2">
                                                                                        <span class="icon-simple"><i class="fa fa-envelope"></i></span>
                                                                                    </div>
                                                                                    <div class="col-sm-9">Direct Message</div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                        <li class="dropdown-item">
                                                                            <a href="javascript:void(0)">
                                                                                <div class="row">
                                                                                    <div class="col-sm-2">
                                                                                        <span class="icon-simple"><i class="fa fa-microphone-slash"></i></span>
                                                                                    </div>
                                                                                    <div class="col-sm-9">Mute</div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                        <li class="dropdown-item">
                                                                            <a href="javascript:void(0)">
                                                                                <div class="row">
                                                                                    <div class="col-sm-2">
                                                                                        <span class="icon-simple"><i class="fa fa-exclamation-circle"></i></span>
                                                                                    </div>
                                                                                    <div class="col-sm-9">Report</div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                        </div>
                                                      </div>
                                                    </div> <!-- end media -->
                                                    <br />
                                                    <div class="text-center">
                                                     <button class="btn btn-outline-info btn-round">Load more tweets</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-4 col-sm-6">
                                                <div class="card card-with-shadow">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Who to follow &middot; <small><a href="javascript: void(0);" class="link-info">View all</a></small></h5>
                                                        <div class="accounts-suggestion">
                                                            <ul class="list-unstyled">
                                                                <li class="account">
                                                                    <div class="row">
                                                                        <div class="col-md-3">
                                                                            <div class="avatar">
                                                                                <img src="./assets/img/chet_faker_1.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-7 description-section">
                                                                            <span>Chet Faker <a href="javascript:void(0)" class="text-muted">@chetfaker</a></span>
                                                                            <br />
                                                                            <span class="text-muted"><small>Followed by <a href="javascript:void(0)" class="link-info">@banks</a> and <a href="javascript:void(0)" class="link-info">@rihanna</a> </small></span>
                                                                        </div>

                                                                        <div class="col-md-2 follow">
                                                                            <button class="btn btn-sm btn-outline-info btn-just-icon"><i class="fa fa-plus"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li class="account">
                                                                    <div class="row">
                                                                        <div class="col-md-3">
                                                                            <div class="avatar">
                                                                                <img src="./assets/img/placeholder.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-7 description-section">
                                                                            <span>John Green <a href="javascript:void(0)" class="text-muted">@johngreen</a></span>
                                                                            <br />
                                                                            <span class="text-muted"><small>Followed by <a href="javascript:void(0)" class="link-info">@rihanna</a> </small></span>
                                                                        </div>

                                                                        <div class="col-md-2 follow">
                                                                            <button class="btn btn-sm btn-outline-info btn-just-icon"><i class="fa fa-plus"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li class="account">
                                                                    <div class="row">
                                                                        <div class="col-md-3">
                                                                            <div class="avatar">
                                                                                <img src="./assets/img/drake.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-7 description-section">
                                                                            <span>Drake <a href="javascript:void(0)" class="text-muted">@drake</a></span>
                                                                            <br />
                                                                            <span class="text-muted"><small>Followed by <a href="javascript:void(0)" class="link-info">@chetfaker</a> </small></span>
                                                                        </div>

                                                                        <div class="col-md-2 follow">
                                                                            <button class="btn btn-sm btn-outline-info btn-just-icon"><i class="fa fa-plus"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div> <!-- end card -->
                                                <div class="card card-with-shadow">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Trends &middot; <small><a href="javascript: void(0);" class="link-info">Change</a></small></h5>
                                                        <div class="hashtag-suggestions">
                                                            <ul class="list-unstyled">
                                                                <li><a href="javascript:void(0)" class="link-danger">#JeSuisToujoursCharlie</a></li>
                                                                <li><a href="javascript:void(0)">Oculus Rift</a></li>
                                                                <li><a href="javascript:void(0)" class="link-danger">#CarenAndLarryAreNotReal</a></li>
                                                                <li><a href="javascript:void(0)" class="link-danger">#Twitter10k</a></li>
                                                                <li><a href="javascript:void(0)">EXCLUSIVE MOVE WITHINGTON</a></li>
                                                                <li><a href="javascript:void(0)">London</a>
                                                                <li><a href="javascript:void(0)">DJ Khaled Snapchat</a>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div> <!-- end card -->
                                            </div>
                                        </div>
                                    </div>

                                </ng-template>
                            </li>
                            <li class="nav-item" [ngbNavItem]="2">
                                <a class="nav-link" ngbNavLink> Connections</a>
                                <ng-template ngbNavContent>
                                    <div class="tab-pane text-center" id="connections" role="tabpanel"></div>
                                </ng-template>
                            </li>
                            <li class="nav-item" [ngbNavItem]="3">
                                <a class="nav-link" ngbNavLink> Media</a>
                                <ng-template ngbNavContent>
                                    <div class="tab-pane" id="media" role="tabpanel"></div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="footer section-gray">
    <div class="container">
        <div class="row">
            <nav class="footer-nav">
                <ul>
                    <li><a href="https://www.creative-tim.com">Creative Tim</a></li>
                    <li><a href="http://blog.creative-tim.com">Blog</a></li>
                    <li><a href="https://www.creative-tim.com/license">Licenses</a></li>
                </ul>
            </nav>
            <div class="credits ml-auto">
                <span class="copyright">
                    © {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Creative Tim
                </span>
            </div>
        </div>
    </div>
</footer>
