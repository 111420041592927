<div class="wrapper">
    <div class="main">
        <div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-12 ml-auto mr-auto text-center">
                        <h2 class="discover-title"><small>See latest artwork</small></h2>
                            <form role="search" class="form-inline">
                                <div class="form-group">
                                  <input type="text" class="form-control border-input" placeholder="Search...">&nbsp;&nbsp;
                                </div>
                                <button type="submit" class="btn btn-just-icon"><i class="fa fa-search"></i></button>
                            </form>
                    </div>
                </div>
                <div class="row items-row">
                    <div class="col-md-3 col-sm-4 ml-auto">
						<div class="card card-plain text-center">
							<div class="card-image">
								<a href="javascript:void(0)">
									<img src="./assets/img/sections/pavel-kosov.jpg" alt="Rounded Image" class="img-rounded img-responsive">
								</a>
								<div class="card-body details-center">
									<a href="javascript:void(0)">
										<div class="author">
											<img src="./assets/img/faces/joe-gardner-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
											<div class="text">
												<span class="name">Jane Doe</span>
												<div class="meta">Drawn on 23 Nov</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
                    </div>
                    <div class="col-md-4 col-sm-4">
						<div class="card card-plain text-center">
							<div class="card-image">
								<a href="javascript:void(0)">
									<img src="./assets/img/sections/por7o.jpg" alt="Rounded Image" class="img-rounded img-responsive">
								</a>
								<div class="card-body details-center">
									<a href="javascript:void(0)">
										<div class="author">
											<img src="./assets/img/faces/erik-lucatero-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
											<div class="text">
												<span class="name">Tom Hanks</span>
												<div class="meta">Drawn on 23 Jan</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
                    </div>
                    <div class="col-md-3 col-sm-4 mr-auto">
						<div class="card card-plain text-center">
							<div class="card-image">
								<a href="javascript:void(0)">
									<img src="./assets/img/sections/vincent-versluis.jpg" alt="Rounded Image" class="img-rounded img-responsive">
								</a>
								<div class="card-body details-center">
									<a href="javascript:void(0)">
										<div class="author">
											<img src="./assets/img/chet_faker_2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
											<div class="text">
												<span class="name">Chet Faker</span>
												<div class="meta">Drawn on 20 Jan</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
                    </div>
                </div>
                <div class="row items-row">
                    <div class="col-md-4 col-sm-6 ml-auto">
						<div class="card card-plain text-center">
							<div class="card-image">
								<a href="javascript:void(0)">
									<img src="./assets/img/sections/ilya-yakover.jpg" alt="Rounded Image" class="img-rounded img-responsive">
								</a>
								<div class="card-body details-center">
									<a href="javascript:void(0)">
										<div class="author">
											<img src="./assets/img/faces/ayo-ogunseinde-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
											<div class="text">
												<span class="name">Tom Hank</span>
												<div class="meta">Drawn on 23 Jan</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
                    </div>
                    <div class="col-md-3 col-sm-6">
						<div class="card card-plain text-center">
							<div class="card-image">
								<a href="javascript:void(0)">
									<img src="./assets/img/sections/miguel-perales.jpg" alt="Rounded Image" class="img-rounded img-responsive">
								</a>
								<div class="card-body details-center">
									<a href="javascript:void(0)">
										<div class="author">
											<img src="./assets/img/faces/clem-onojeghuo-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
											<div class="text">
												<span class="name">Tom Banks</span>
												<div class="meta">Drawn on 23 Jan</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
                    </div>
                    <div class="col-md-3 mr-auto d-sm-block">
						<div class="card card-plain text-center">
							<div class="card-image">
								<a href="javascript:void(0)">
									<img src="./assets/img/sections/neill-kumar.jpg" alt="Rounded Image" class="img-rounded img-responsive">
								</a>
								<div class="card-body details-center">
									<a href="javascript:void(0)">
										<div class="author">
											<img src="./assets/img/flume.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
											<div class="text">
												<span class="name">Flume</span>
												<div class="meta">Drawn on 4 Aug</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
                    </div>
                </div>
                <div class="row items-row">
                    <div class="col-md-2 col-sm-6 ml-auto">
						<div class="card card-plain text-center">
							<div class="card-image">
								<a href="javascript:void(0)">
									<img src="./assets/img/sections/john-towner.jpg" alt="Rounded Image" class="img-rounded img-responsive">
								</a>
								<div class="card-body details-center">
									<a href="javascript:void(0)">
										<div class="author">
											<img src="./assets/img/placeholder.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
											<div class="text">
												<span class="name">Tom Hanks</span>
												<div class="meta">Drawn on 23 Jan</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
                    </div>
                    <div class="col-md-4 col-sm-6">
						<div class="card card-plain text-center">
							<div class="card-image">
								<a href="javascript:void(0)">
									<img src="./assets/img/sections/leonard-cotte.jpg" alt="Rounded Image" class="img-rounded img-responsive">
								</a>
								<div class="card-body details-center">
									<a href="javascript:void(0)">
										<div class="author">
											<img src="./assets/img/placeholder.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
											<div class="text">
												<span class="name">Banks</span>
												<div class="meta">Drawn on 3 Mar</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
                    </div>
                    <div class="col-md-4 col-sm-6 mr-auto">
						<div class="card card-plain text-center">
							<div class="card-image">
								<a href="javascript:void(0)">
									<img src="./assets/img/sections/anders-jilden.jpg" alt="Rounded Image" class="img-rounded img-responsive">
								</a>
								<div class="card-body details-center">
									<a href="javascript:void(0)">
										<div class="author">
											<img src="./assets/img/faces/erik-lucatero-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
											<div class="text">
												<span class="name">Tom Hanks</span>
												<div class="meta">Drawn on 23 Jan</div>
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 text-center ml-auto mr-auto">
                        <div class="preloader">
                            <div class='uil-reload-css' style=''><div></div></div> <h5>Loading More  </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<footer class="footer">
	<div class="container">
		<div class="row">
			<nav class="footer-nav">
				<ul>
					<li><a href="https://www.creative-tim.com">Creative Tim</a></li>
					<li><a href="http://blog.creative-tim.com">Blog</a></li>
					<li><a href="https://www.creative-tim.com/license">Licenses</a></li>
				</ul>
			</nav>
			<div class="credits ml-auto">
				<span class="copyright">
					© {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Creative Tim
				</span>
			</div>
		</div>
	</div>
</footer>
