<div class="main">
    <div class="section section-gray">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">Get in touch with us</h2>
                    <p>Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualise customer directed convergence without revolutionary ROI.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                    <h3 class="title"><small>Find us on social networks</small></h3>
                    <button class="btn btn-just-icon btn-twitter mr-1">
                        <i class="fa fa-twitter"></i>
                    </button>
                    <button class="btn btn-just-icon btn-facebook mr-1">
                        <i class="fa fa-facebook"></i>
                    </button>
                    <button class="btn btn-just-icon btn-google mr-1">
                        <i class="fa fa-google"></i>
                    </button>
                    <button class="btn btn-just-icon btn-dribbble mr-1">
                        <i class="fa fa-dribbble"></i>
                    </button>
                    <button class="btn btn-just-icon btn-instagram mr-1">
                        <i class="fa fa-instagram"></i>
                    </button>
                    <button class="btn btn-just-icon btn-youtube">
                        <i class="fa fa-youtube"></i>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                    <h3 class="title"><small>Or drop us a note</small></h3>
                    <form class="contact">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="First Name">
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Last Name">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Email">
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="Subject">
                            </div>
                        </div>
                        <textarea class="form-control" placeholder="Message" rows="7" ></textarea>

                        <div class="row">
                            <div class="col-md-6 ml-auto mr-auto">
                                <button class="btn btn-primary btn-block btn-round">Send </button>
                            </div>
                        </div>
                    </form>
                    <h3 class="visit"><small>Or come and visit</small></h3>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="big-map">
    <!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [styles]="styles">
        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
    </agm-map> -->
</div>

<footer class="footer section-nude">
    <div class="container">
        <div class="row">
            <nav class="footer-nav">
                <ul>
                    <li><a href="https://www.creative-tim.com">Creative Tim</a></li>
                    <li><a href="http://blog.creative-tim.com">Blog</a></li>
                    <li><a href="https://www.creative-tim.com/license">Licenses</a></li>
                </ul>
            </nav>
            <div class="credits ml-auto">
                <span class="copyright">
                    © {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Creative Tim
                </span>
            </div>
        </div>
    </div>
</footer>
