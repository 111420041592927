<div class="page-header page-header-small" style="background-image: url('./assets/img/sections/gerrit-vermeulen.jpg');">
    <div class="filter filter-danger"></div>
    <div class="content-center">
        <div class="container">
            <h1>Hello, <br /> We are Creative Tim.</h1>
            <h3>Let us tell you more about what we do.</h3>
        </div>
    </div>
</div>


<div class="wrapper">
<div class="main">
<div class="section">
    <div class="container">
        <h3 class="title-uppercase">We build great products.</h3>
        <p>Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer directed convergence without revolutionary ROI.</p>

        <p>Efficiently unleash cross-media information without cross-media value. Quickly maximize timely deliverables for real-time schemas. Dramatically maintain clicks-and-mortar solutions without functional solutions. </p>

        <h3 class="title-uppercase">We <i class="fa fa-heart heart"></i>&nbsp; what we do.</h3>
        <p>Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service with robust ideas. Dynamically innovate resource-leveling customer service for state of the art customer service.</p>
        <h2 class="text-center creators">Creators</h2>
        <div class="row">
            <div class="col-md-4">
                <div class="card card-profile card-plain">
                    <div class="card-body">
                        <div class="card-avatar">
                            <a href="#avatar">
                                <img src="./assets/img/faces/ayo-ogunseinde-2.jpg" alt="...">
                                <h4 class="card-title">Eric Thomson</h4>
                            </a>
                        </div>
                        <p class="card-description text-center">
                            A group becomes a team when each member is sure enough of himself and his contribution to praise the skill of the others. No one can whistle a symphony. It takes orchestra to play it.
                        </p>
                    </div>
                    <div class="card-footer text-center">
                        <a href="javascript:void(0)" class="btn btn-just-icon btn-linkedin mr-1"><i class="fa fa-linkedin"></i></a>
                        <a href="javascript:void(0)" class="btn btn-just-icon btn-dribbble mr-1"><i class="fa fa-dribbble"></i></a>
                        <a href="javascript:void(0)" class="btn btn-just-icon btn-instagram"><i class="fa fa-instagram"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card card-profile card-plain">
                    <div class="card-body">
                        <div class="card-avatar">
                            <a href="#avatar">
                                <img src="./assets/img/faces/joe-gardner-2.jpg" alt="...">
                                <h4 class="card-title">Sophia West</h4>
                            </a>
                        </div>
                        <p class="card-description text-center">
                            The strength of the team is each individual member. The strength of each member is the team. If you can laugh together, you can work together, silence isn’t golden, it’s deadly.
                        </p>
                    </div>
                    <div class="card-footer text-center">
                        <a href="javascript:void(0)" class="btn btn-just-icon btn-linkedin mr-1"><i class="fa fa-linkedin"></i></a>
                        <a href="javascript:void(0)" class="btn btn-just-icon btn-dribbble mr-1"><i class="fa fa-dribbble"></i></a>
                        <a href="javascript:void(0)" class="btn btn-just-icon btn-pinterest"><i class="fa fa-pinterest"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card card-profile card-plain">
                    <div class="card-body">
                        <div class="card-avatar">
                            <a href="#avatar">
                                <img src="./assets/img/faces/clem-onojeghuo-2.jpg" alt="...">
                                <h4 class="card-title">Lucas Andrew</h4>
                            </a>
                        </div>
                        <p class="card-description text-center">
                            Great teams do not hold back with one another. They are unafraid to air their dirty laundry. They admit their mistakes, their weaknesses and their concerns without fear of reprisal.
                        </p>
                    </div>
                    <div class="card-footer text-center">
                        <a href="javascript:void(0)" class="btn btn-just-icon btn-youtube mr-1"><i class="fa fa-youtube"></i></a>
                        <a href="javascript:void(0)" class="btn btn-just-icon btn-twitter mr-1"><i class="fa fa-twitter"></i></a>
                        <a href="javascript:void(0)" class="btn btn-just-icon btn-instagram"><i class="fa fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <h3 class="more-info">Need more information?</h3>

        <div class="row coloured-cards">
            <div class="col-md-4 col-sm-6">
                <div class="card-big-shadow">
                    <div class="card card-just-text" data-background="color" data-color="blue" data-radius="none">
                        <div class="card-body">
                            <h6 class="card-category">Best cards</h6>
                            <h4 class="card-title"><a href="javascript:void(0)">Blue Card</a></h4>
                            <p class="card-description">What all of these have in common is that they're pulling information out of the app or the service and making it relevant to the moment. </p>
                        </div>
                    </div> <!-- end card -->
                </div>
            </div>

            <div class="col-md-4 col-sm-6">
                <div class="card-big-shadow">
                    <div class="card card-just-text" data-background="color" data-color="green" data-radius="none">
                        <div class="card-body">
                            <h6 class="card-category">Best cards</h6>
                            <h4 class="card-title"><a href="javascript:void(0)">Green Card</a></h4>
                            <p class="card-description">What all of these have in common is that they're pulling information out of the app or the service and making it relevant to the moment. </p>
                        </div>
                    </div> <!-- end card -->
                </div>
            </div>

            <div class="col-md-4 col-sm-6">
                <div class="card-big-shadow">
                    <div class="card card-just-text" data-background="color" data-color="yellow" data-radius="none">
                        <div class="card-body">
                            <h6 class="card-category">Best cards</h6>
                            <h4 class="card-title"><a href="javascript:void(0)">Yellow Card</a></h4>
                            <p class="card-description">What all of these have in common is that they're pulling information out of the app or the service and making it relevant to the moment. </p>
                        </div>
                    </div> <!-- end card -->
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>


<footer class="footer footer-big footer-black">

<div class="container">
<div class="row">
    <div class="col-md-2 col-sm-3 col-6 ml-auto mr-auto">
         <div class="form-group dropup">
             <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                 [settings]="dropdownSettings"
                 (onSelect)="onItemSelect($event)"
                 (onDeSelect)="OnItemDeSelect($event)"
                 (onSelectAll)="onSelectAll($event)"
                 (onDeSelectAll)="onDeSelectAll($event)">
             </angular2-multiselect>
        </div>
         <div class="form-group dropup">
             <angular2-multiselect [data]="dropdownList1" [(ngModel)]="selectedItems1"
                 [settings]="dropdownSettings1"
                 (onSelect)="onItemSelect($event)"
                 (onDeSelect)="OnItemDeSelect($event)"
                 (onSelectAll)="onSelectAll($event)"
                 (onDeSelectAll)="onDeSelectAll($event)">
             </angular2-multiselect>
        </div>
    </div>
    <div class="col-md-9 ml-auto mr-auto col-sm-9 col-12">
        <div class="row">
            <div class="col-md-3 col-sm-3 col-6">
                <div class="links">
                    <ul class="uppercase-links stacked-links">
                        <li>
                            <a href="javascript:void(0)" >
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                Discover
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                Blog
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3 col-sm-3 col-6">
                <div class="links">
                    <ul class="uppercase-links stacked-links">
                        <li>
                            <a href="javascript:void(0)">
                               Contact Us
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                               We're Hiring
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                               About Us
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3 col-sm-3 col-6">
                <div class="links">
                    <ul class="uppercase-links stacked-links">
                        <li>
                            <a href="javascript:void(0)">
                                Portfolio
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                               How it works
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                               Testimonials
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3 col-6">
                <div class="social-area">
                    <a class="btn btn-just-icon btn-round btn-facebook mr-1">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a class="btn btn-just-icon btn-round btn-twitter mr-1">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a class="btn btn-just-icon btn-round btn-google mr-1">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                    <a class="btn btn-just-icon btn-round btn-pinterest">
                        <i class="fa fa-pinterest-p" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
        <hr />
        <div class="copyright">
            <div class="pull-left">
                © {{data | date: 'yyyy'}} Creative Tim, made with love
            </div>
            <div class="links pull-right">
                <ul>
                    <li>
                        <a href="javascript:void(0)">
                            Company Policy
                        </a>
                    </li>
                    |
                    <li>
                        <a href="javascript:void(0)">
                            Terms
                        </a>
                    </li>
                    |
                    <li>
                        <a href="javascript:void(0)">
                            Privacy
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

</div>
</footer>
