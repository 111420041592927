<div class="section section-pricing cd-section" id="pricing">

    <!--     *********    PRICING 1     *********      -->
    <div class="pricing-1 section">
        <div class="container">
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                    <h2 class="title">Pick the best plan for you</h2>
                    <h5 class="description">You have Free Unlimited Updates and Premium Support on each package.</h5>
                </div>
            </div>
            <div class="space-top"></div>
            <div class="row">
                <div class="col-md-3">
                    <div class="card card-pricing" data-color="orange">
                        <div class="card-body">
                            <div class="card-icon">
                                <span class="icon-simple"><i class="nc-icon nc-basket"></i></span>
                            </div>
                            <h3 class="card-title">$199</h3>

                            <p class="card-description">
                                This is good if your company size is between 5 and 20 employees.
                            </p>
                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-neutral btn-round">Choose Plan</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-pricing" data-color="yellow">
                        <div class="card-body">
                            <div class="card-icon">
                                <span class="icon-simple"><i class="nc-icon nc-watch-time"></i></span>
                            </div>
                            <h3 class="card-title">$249</h3>

                            <p class="card-description">
                                This is good if your company size is between 21 and 59 employees.
                            </p>
                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-neutral btn-round">Choose Plan</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-pricing" data-color="green">
                        <div class="card-body">
                            <div class="card-icon">
                                <span class="icon-simple"><i class="nc-icon nc-settings"></i></span>
                            </div>
                            <h3 class="card-title">$359</h3>

                            <p class="card-description">
                                This is good if your company size is between 60 and 159 employees.
                            </p>
                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-neutral btn-round">Choose Plan</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-pricing" data-color="blue">
                        <div class="card-body">
                            <div class="card-icon">
                                <span class="icon-simple"><i class="nc-icon nc-istanbul"></i></span>
                            </div>
                            <h3 class="card-title">$599</h3>

                            <p class="card-description">
                                This is good if your company size is between 160 and 299 employees.
                            </p>
                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-neutral btn-round">Choose Plan</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--     *********    PRICING 2     *********      -->
    <div class="pricing-2">
        <div class="container">
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                    <h2 class="title">Pick the best plan for you</h2>
                    <h5 class="description">You have Free Unlimited Updates and Premium Support on each package.</h5>
                    <br>
                    <ul ngbNav #nav2="ngbNav" [(activeId)]="activeTab" type="pills"
                        class="nav nav-pills nav-pills-danger justify-content-center">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                Monthly
                            </a>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                Yearly
                            </a>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav2" class="mt-2"></div>
                </div>
            </div>
            <div class="space-top"></div>

            <div class="row">
                <div class="col-md-4">
                    <div class="card card-pricing" data-background="image"
                        style="background-image: url('assets/img/sections/daniel-olahh.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">Free</h6>
                            <h1 class="card-title"><small>$</small>0<small>/mo</small></h1>
                            <ul>
                                <li><b>5</b> Sharing Tools</li>
                                <li><b>10</b> Design Tools</li>
                                <li><b>100</b> Private Messages</li>
                                <li><b>2</b> Personal Brand</li>
                            </ul>
                            <a href="javascript:void(0)" class="btn btn-warning btn-round ">
                                Get Started
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-pricing" data-background="image"
                        style="background-image: url('assets/img/sections/forest-bg.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">Premium</h6>
                            <h1 class="card-title"><small>$</small>89<small>/mo</small></h1>
                            <ul>
                                <li><b>100</b> Projects</li>
                                <li><b>5</b> Team Members</li>
                                <li><b>55</b> Personal Contacts</li>
                                <li><b>5.000</b> Messages</li>
                            </ul>
                            <a href="javascript:void(0)" class="btn btn-success btn-round">
                                Get Started
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-pricing" data-background="image"
                        style="background-image: url('assets/img/sections/anders-jilden.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">Platinum</h6>
                            <h1 class="card-title"><small>$</small>199<small>/mo</small></h1>
                            <ul>
                                <li><b>1</b> Project</li>
                                <li><b>5</b> Team Members</li>
                                <li><b>55</b> Personal Contacts</li>
                                <li><b>5.000</b> Messages</li>
                            </ul>
                            <a href="javascript:void(0)" class="btn btn-danger btn-round ">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--     *********    PRICING 3     *********      -->
    <div class="pricing-3 section-image" style="background-image: url('assets/img/sections/daniel-olahs.jpg')">
        <div class="container">
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                    <h2 class="title">Pick the best plan for you</h2>
                    <h5 class="description">You have Free Unlimited Updates and Premium Support on each package.</h5>
                </div>
            </div>
            <div class="space-top"></div>
            <div class="row">
                <div class="col-md-3 ml-auto mr-auto">
                    <div class="card card-pricing">
                        <div class="card-body">
                            <h6 class="card-category">Single User</h6>
                            <div class="card-icon">
                                <i class="nc-icon nc-user-run"></i>
                            </div>
                            <h3 class="card-title">$29</h3>
                            <p class="card-description">
                                Here’s a sentence I never expected: we are making...
                            </p>
                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-info btn-round card-link">Choose Plan</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 mr-auto">
                    <div class="card card-pricing" data-background="color" data-color="blue">
                        <div class="card-body">
                            <h6 class="card-category">Small company</h6>
                            <div class="card-icon">
                                <i class="nc-icon nc-air-baloon"></i>
                            </div>
                            <h3 class="card-title">$59</h3>
                            <p class="card-description">

                                Sometimes combining two companies that compete in the same market provides...
                            </p>
                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-neutral btn-round card-link">Choose Plan</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 mr-auto">
                    <div class="card card-pricing">
                        <div class="card-body">
                            <h6 class="card-category">Large Company</h6>
                            <div class="card-icon">
                                <i class="nc-icon nc-istanbul"></i>
                            </div>
                            <h3 class="card-title">$199</h3>
                            <p class="card-description">
                                This is good if you are a freelancer of photographer.
                            </p>
                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-info btn-round card-link">Choose Plan</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--     *********    PRICING 4     *********      -->
    <div class="pricing-4 section section-dark">

        <div class="container">
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                    <h2 class="title">Pick the best plan for you</h2>
                    <h5 class="description">You have Free Unlimited Updates and Premium Support on each package.</h5>
                </div>
            </div>
            <div class="space-top"></div>
            <div class="row">
                <div class="col-md-3">
                    <div class="card card-pricing card-plain">
                        <div class="card-body">
                            <h6 class="card-category text-success">Startup</h6>
                            <h1 class="card-title">$0</h1>
                            <ul class="text-white">
                                <li><i class="fa fa-check" aria-hidden="true"></i> Sharing Tools</li>
                                <li><i class="fa fa-check" aria-hidden="true"></i> Design Tools</li>
                                <li><i class="fa fa-times" aria-hidden="true"></i> Private Messages</li>
                                <li><i class="fa fa-times" aria-hidden="true"></i> Personal Brand</li>
                            </ul>
                            <a href="javascript:void(0)" class="btn btn-outline-neutral btn-round">Downgrade plan</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-pricing">
                        <div class="card-body">
                            <h6 class="card-category text-success">Small company</h6>
                            <h1 class="card-title">$89</h1>
                            <ul>
                                <li><i class="fa fa-check" aria-hidden="true"></i> Sharing Tools</li>
                                <li><i class="fa fa-check" aria-hidden="true"></i> Design Tools</li>
                                <li><i class="fa fa-times" aria-hidden="true"></i> Private Messages</li>
                                <li><i class="fa fa-times" aria-hidden="true"></i> Personal Brand</li>
                            </ul>
                            <a href="javascript:void(0)" class="btn btn-outline-success btn-round">Current plan</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-pricing card-plain">
                        <div class="card-body">
                            <h6 class="card-category text-success">Large Company</h6>
                            <h1 class="card-title">$189</h1>
                            <ul class="text-white">
                                <li><i class="fa fa-check" aria-hidden="true"></i> Sharing Tools</li>
                                <li><i class="fa fa-check" aria-hidden="true"></i> Design Tools</li>
                                <li><i class="fa fa-times" aria-hidden="true"></i> Private Messages</li>
                                <li><i class="fa fa-times" aria-hidden="true"></i> Personal Brand</li>
                            </ul>
                            <a href="javascript:void(0)" class="btn btn-outline-neutral btn-round">Upgrade plan</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-pricing card-plain">
                        <div class="card-body">
                            <h6 class="card-category text-success">Enterprise</h6>
                            <h1 class="card-title">$389</h1>
                            <ul class="text-white">
                                <li><i class="fa fa-check" aria-hidden="true"></i> Sharing Tools</li>
                                <li><i class="fa fa-check" aria-hidden="true"></i> Design Tools</li>
                                <li><i class="fa fa-times" aria-hidden="true"></i> Private Messages</li>
                                <li><i class="fa fa-times" aria-hidden="true"></i> Personal Brand</li>
                            </ul>
                            <a href="javascript:void(0)" class="btn btn-outline-neutral btn-round">Upgrade plan</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--     *********    PRICING 5     *********      -->

    <div class="pricing-5 section-gray">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <h2 class="title text-center">Choose a plan for your next project</h2>

                    <br />
                    <p class="description text-gray">You have Free Unlimited Updates and Premium Support on each
                        package. You also have 20 days to request a refund if you're not happy with your purchase.</p>
                </div>
                <div class="col-md-8">
                    <div class="choose-plan">
                        <!-- <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper"> -->
                        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab1" type="pills"
                            class="nav-pills-danger justify-content-center">
                            <li class="nav-item" [ngbNavItem]="1">
                                <a class="nav-link" ngbNavLink> Personal</a>
                                <ng-template ngbNavContent>
                                    <div class="space-top"></div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="card card-pricing">
                                                <div class="card-body">
                                                    <h6 class="card-category text-primary">HTML Package</h6>
                                                    <h1 class="card-title">$0</h1>
                                                    <ul>
                                                        <li><b>1</b> Developer</li>
                                                        <li><b>99+</b> Components</li>
                                                        <li><b>HTML</b> Elements</li>
                                                        <li><b>14</b> Page Examples</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-primary btn-round">Free
                                                        download</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="card card-pricing" data-color="orange">
                                                <div class="card-body">
                                                    <h6 class="card-category text-success">HTML Package</h6>
                                                    <h1 class="card-title">$59</h1>
                                                    <ul>
                                                        <li><b>5</b> Developer</li>
                                                        <li><b>129+</b> Components</li>
                                                        <li><b>HTML</b> Elements</li>
                                                        <li><b>24</b> Page Examples</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-neutral btn-round">Free
                                                        download</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li class="nav-item" [ngbNavItem]="2">
                                <a class="nav-link" ngbNavLink> Commercial</a>
                                <ng-template ngbNavContent>
                                    <div class="space-top"></div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="card card-pricing">
                                                <div class="card-body">
                                                    <h6 class="card-category text-warning">HTML Package</h6>
                                                    <h1 class="card-title">$159</h1>
                                                    <ul>
                                                        <li><b>5+</b> Developers</li>
                                                        <li><b>199+</b> Components</li>
                                                        <li><b>HTML</b> Elements</li>
                                                        <li><b>34</b> Page Examples</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-warning btn-round">Buy
                                                        Now !</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="card card-pricing" data-color="orange">
                                                <div class="card-body">
                                                    <h6 class="card-category text-success">HTML Package</h6>
                                                    <h1 class="card-title">$359</h1>
                                                    <ul>
                                                        <li><b>15</b> Developer</li>
                                                        <li><b>229+</b> Components</li>
                                                        <li><b>HTML</b> Elements</li>
                                                        <li><b>44</b> Page Examples</li>
                                                    </ul>
                                                    <a href="javascript:void(0)" class="btn btn-neutral btn-round">Free
                                                        download</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        <!-- </div>
                        </div> -->
                    </div>
                </div>

                <!-- <div class="col-md-7 ml-auto">
                    <div class="tab-content text-center" >
                        <div class="tab-pane active" id="personal" role="tabpanel">
                            <div class="space-top"></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card card-pricing">
                                        <div class="card-body">
                                            <h6 class="card-category text-primary">HTML Package</h6>
                                            <h1 class="card-title">$0</h1>
                                            <ul>
                                                <li><b>1</b> Developer</li>
                                                <li><b>99+</b> Components</li>
                                                <li><b>HTML</b> Elements</li>
                                                <li><b>14</b> Page Examples</li>
                                            </ul>
                                            <a href="javascript:void(0)" class="btn btn-primary btn-round">Free download</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card card-pricing" data-color="orange">
                                        <div class="card-body">
                                            <h6 class="card-category text-success">HTML Package</h6>
                                            <h1 class="card-title">$59</h1>
                                            <ul>
                                                <li><b>5</b> Developer</li>
                                                <li><b>129+</b> Components</li>
                                                <li><b>HTML</b> Elements</li>
                                                <li><b>24</b> Page Examples</li>
                                            </ul>
                                            <a href="javascript:void(0)" class="btn btn-neutral btn-round">Free download</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane" id="commercial" role="tabpanel">
                            <div class="space-top"></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card card-pricing">
                                        <div class="card-body">
                                            <h6 class="card-category text-warning">HTML Package</h6>
                                            <h1 class="card-title">$159</h1>
                                            <ul>
                                                <li><b>5+</b> Developers</li>
                                                <li><b>199+</b> Components</li>
                                                <li><b>HTML</b> Elements</li>
                                                <li><b>34</b> Page Examples</li>
                                            </ul>
                                            <a href="javascript:void(0)" class="btn btn-warning btn-round">Buy Now !</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="card card-pricing" data-color="orange">
                                        <div class="card-body">
                                            <h6 class="card-category text-success">HTML Package</h6>
                                            <h1 class="card-title">$359</h1>
                                            <ul>
                                                <li><b>15</b> Developer</li>
                                                <li><b>229+</b> Components</li>
                                                <li><b>HTML</b> Elements</li>
                                                <li><b>44</b> Page Examples</li>
                                            </ul>
                                            <a href="javascript:void(0)" class="btn btn-neutral btn-round">Free download</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>