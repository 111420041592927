<div class="page-header" style="background-image: url('./assets/img/ecommerce/balmain_runway.jpg');">
    <div class="filter"></div>
    <div class="content-center">
        <div class="container text-center">
            <h1>Nova Coleção</h1>
            <h3>A entrega dentro do Brasil é gratuita.* </h3>
        </div>
    </div>
</div>

<!-- Info Areas -->


<div class="col-md-4">
    <div class="info">
        
            <img src="./assets/img/delivery.png" width="60">
        
        <div class="description">
            <h4 class="info-title"> Frete Grátis </h4>
            <p>*A entrega dentro do Brasil é gratuita nas compras acima R$ 100.</p>
        </div>
    </div>
</div>
<div class="col-md-4">
    <div class="info">
    <img src="./assets/img/moto.png" width="40">
        <div class="description">
            <h4 class="info-title"> Motoboy </h4>
            <p>Receba sua compra no mesmo dia comprando até as 12:00 São Paulo e Grande São Paulo</p>
        </div>
    </div>
</div>
<div class="col-md-4">
    <div class="info">
    <img src="./assets/img/credit.png" width="40">
        <div class="description">
            <h4 class="info-title"> Pague em Até 12x sem juro </h4>
            <p>Cartões de Crédito ou à vista</p>
        </div>
    </div>
</div>


<!-- Info Areas END-->


<div class="wrapper">


    
 <div class="section latest-offers">
       <div class="container">
           <h3 class="section-title">Latest Offers</h3>
           <div class="row">
                <div class="col-md-4">
                    <div class="card card-product card-plain">
                        <div class="card-image">
                            <a href="javascript:void(0)">
                                <img src="./assets/img/ecommerce/balmain_5.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                            </a>
                            <div class="card-body">
                                <div class="card-description">
                                    <h5 class="card-title">Green Velvet Dress</h5>
                                    <p class="card-description">This is a limited edition dress for the fall collection. Comes in 3 colours.</p>
                                </div>
                                <div class="price">
                                    <s>3.520 &euro;</s> <span class="text-danger">2.900 &euro;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
                <div class="col-md-4">
                    <div class="card card-product card-plain">
                        <div class="card-image">
                            <a href="javascript:void(0)">
                                <img src="./assets/img/ecommerce/balmain_3.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                            </a>
                            <div class="card-body">
                                <div class="card-description">
                                    <h5 class="card-title">Crepe and Organza Dress</h5>
                                    <p class="card-description">This is a limited edition dress for the fall collection. Comes in 3 colours.</p>
                                </div>
                                <div class="price">
                                    <s>2.675 &euro;</s> <span class="text-danger">2.000 &euro;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
                <div class="col-md-4">
                    <div class="card card-product card-plain">
                        <div class="card-image">
                            <a href="javascript:void(0)">
                                <img src="./assets/img/ecommerce/balmain_4.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                            </a>
                            <div class="card-body">
                                <div class="card-description">
                                    <h5 class="card-title">Lace Mini Dress</h5>
                                    <p class="card-description">This is a limited edition dress for the fall collection. Comes in 3 colours.</p>
                                </div>
                                <div class="price">
                                    <s>3.915 &euro;</s> <span class="text-danger">3.125 &euro;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
           </div>
       </div>
</div><!-- section -->

<div class="section section-gray">
       <div class="container">
           <h3 class="section-title">Find what you need</h3>
           <div class="row">
                <div class="col-md-3">
                    <div class="card card-refine">
                        <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
                            <ngb-panel id="config-panel-one">
                                <ng-template ngbPanelTitle>
                                    <h5 class="mb-0 panel-title">
                                        <a class="collapsed">
                                            Price Range
                                            <i class="nc-icon nc-minimal-down"></i>
                                        </a>
                                    </h5>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <nouislider class="slider slider-info" [connect]="true" [min]="0" [max]="10000" [step]="10" [(ngModel)]="doubleSlider" [tooltips]="true"></nouislider>                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <h5 class="mb-0 panel-title">
                                        <a class="collapsed">
                                            Clothing
                                            <i class="nc-icon nc-minimal-down"></i>
                                        </a>
                                    </h5>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="" checked>
                                            Blazers
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Casual Shirts
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Formal Shirts
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Jeans
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Polos
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Pyjamas
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Shorts
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Trousers
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <h5 class="mb-0 panel-title">
                                        <a class="collapsed">
                                            Designer
                                            <i class="nc-icon nc-minimal-down"></i>
                                        </a>
                                    </h5>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="" checked>
                                            All
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Acne Studio
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Alex Mill
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Alexander McQueen
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Alfred Dunhill
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            AMI
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Berena
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Berluti
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Burberry Prorsum
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Berluti
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Calvin Klein
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Club Monaco
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Dolce & Gabbana
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Gucci
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Kolor
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Lanvin
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Loro Piana
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Massimo Alba
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <h5 class="mb-0 panel-title">
                                        <a class="collapsed">
                                            Colour
                                            <i class="nc-icon nc-minimal-down"></i>
                                        </a>
                                    </h5>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="" checked>
                                            All
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Blue
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Brown
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Gray
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Green
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Neutrals
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="checkbox" value="">
                                            Purple
                                            <span class="form-check-sign"></span>
                                        </label>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div> <!-- end card -->
                </div>

                <div class="col-md-9">
                    <div class="products">
                        <div class="row">
                            <div class="col-md-4 col-sm-4">
                                <div class="card card-product card-plain">
                                    <div class="card-image">
                                        <a href="javascript:void(0)">
                                            <img src="./assets/img/ecommerce/balmain_1.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                                        </a>
                                        <div class="card-body">
                                            <div class="card-description">
                                                <h5 class="card-title">One Shoulder Dress</h5>
                                                <p class="card-description">Dresses & Skirts</p>
                                            </div>
                                            <div class="price">
                                                <h5>2.900 &euro;</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <div class="card card-product card-plain">
                                    <div class="card-image">
                                        <a href="javascript:void(0)">
                                            <img src="./assets/img/ecommerce/balmain_2.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                                        </a>
                                        <div class="card-body">
                                            <div class="card-description">
                                                <h5 class="card-title">Stretch-Knit Dress</h5>
                                                <p class="card-description">Dresses & Skirts</p>
                                            </div>
                                            <div class="price">
                                                <h5>1.700 &euro;</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <div class="card card-product card-plain">
                                    <div class="card-image">
                                        <a href="javascript:void(0)">
                                            <img src="./assets/img/ecommerce/balmain_2.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                                        </a>
                                        <div class="card-body">
                                            <div class="card-description">
                                                <h5 class="card-title">Chrystal Sheer Dress</h5>
                                                <p class="card-description">Dresses & Skirts</p>
                                            </div>
                                            <div class="price">
                                                <h5>1.500 &euro;</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <div class="card card-product card-plain">
                                    <div class="card-image">
                                        <a href="javascript:void(0)">
                                            <img src="./assets/img/ecommerce/balmain_2.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                                        </a>
                                        <div class="card-body">
                                            <div class="card-description">
                                                <h5 class="card-title">One Shoulder Dress</h5>
                                                <p class="card-description">Dresses & Skirts</p>
                                            </div>
                                            <div class="price">
                                                <h5>2.600 &euro;</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <div class="card card-product card-plain">
                                    <div class="card-image">
                                        <a href="javascript:void(0)">
                                            <img src="./assets/img/ecommerce/balmain_8.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                                        </a>
                                        <div class="card-body">
                                            <div class="card-description">
                                                <h5 class="card-title">Glass Beads Skirt</h5>
                                                <p class="card-description">Dresses & Skirts</p>
                                            </div>
                                            <div class="price">
                                                <h5>7.500 &euro;</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4">
                                <div class="card card-product card-plain">
                                    <div class="card-image">
                                        <a href="javascript:void(0)">
                                            <img src="./assets/img/ecommerce/balmain_8.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                                        </a>
                                        <div class="card-body">
                                            <div class="card-description">
                                                <h5 class="card-title">Velvet Mini Dress</h5>
                                                <p class="card-description">Dresses & Skirts</p>
                                            </div>
                                            <div class="price">
                                                <h5>3.500 &euro;</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <div class="col-md-3 offset-md-4">
                                  <button rel="tooltip" title="This is a morphing button" class="btn btn-round btn-outline-default" id="successBtn" data-toggle="morphing" data-rotation-color="gray">Load more...</button>
                             </div>
                        </div>
                    </div>
                </div>
           </div>
       </div>
</div><!-- section -->


 <div class="section section-blog">
       <div class="container">
           <div class="row">
                <div class="col-md-4">
                    <div class="card card-blog">
                        <div class="card-image">
                            <a href="javascript:void(0)">
                                <img class="img img-raised" src="./assets/img/sections/miguel-perales.jpg">
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="card-category text-info">Enterprise</h6>
                            <h5 class="card-title">
                                <a href="javascript:void(0)">LinkedIn’s new desktop app arrives</a>
                            </h5>
                            <p class="card-description">
                                LinkedIn is today launching its official desktop application for Windows 10, allowing the professional social networking service to... <br>
                            </p>
                            <hr>
                            <div class="card-footer">
                                <div class="author">
                                    <a href="javascript:void(0)">
                                       <img src="./assets/img/faces/ayo-ogunseinde-2.jpg" alt="..." class="avatar img-raised">
                                       <span>Mike John</span>
                                    </a>
                                </div>
                                <div class="stats">
                                        <i class="fa fa-clock-o" aria-hidden="true"></i> 5 min read
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-blog">
                        <div class="card-image">
                            <a href="javascript:void(0)">
                                <img class="img img-raised" src="./assets/img/sections/roger-keller.jpg">
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="card-category text-success">
                                Startups
                            </h6>
                            <h5 class="card-title">
                                <a href="javascript:void(0)">MIT’s Cheetah 3 robot is built to save lives</a>
                            </h5>
                            <p class="card-description">
                                The latest version of MIT’s Cheetah robot made its stage debut today at TC Sessions: Robotics in Cambridge, Mass. It’s a familiar project... <br>
                            </p>
                            <hr>
                            <div class="card-footer">
                                <div class="author">
                                    <a href="javascript:void(0)">
                                       <img src="./assets/img/faces/kaci-baum-2.jpg" alt="..." class="avatar img-raised">
                                       <span>Nickie Kelly</span>
                                    </a>
                                </div>
                                <div class="stats">
                                        <i class="fa fa-clock-o" aria-hidden="true"></i> 5 min read
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-blog">
                        <div class="card-image">
                            <a href="javascript:void(0)">
                                <img class="img img-raised" src="./assets/img/sections/joshua-earlesz.jpg">
                            </a>
                        </div>

                        <div class="card-body">
                            <h6 class="card-category text-danger">
                                <i class="fa fa-free-code-camp" aria-hidden="true"></i> Enterprise
                            </h6>
                            <h5 class="card-title">
                                <a href="javascript:void(0)">Lionel Richie says “Hello” to startup investors</a>
                            </h5>
                            <p class="card-description">
                                Because developing a doctor-on-demand service that would allow personalized medical visits, booked through an app on a user’s phone is... <br>
                            </p>
                            <hr>
                            <div class="card-footer">
                                <div class="author">
                                    <a href="javascript:void(0)">
                                       <img src="./assets/img/faces/erik-lucatero-2.jpg" alt="..." class="avatar img-raised">
                                       <span>Mike John</span>
                                    </a>
                                </div>
                                <div class="stats">
                                        <i class="fa fa-clock-o" aria-hidden="true"></i> 5 min read
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
</div><!-- section -->
<div class="subscribe-line subscribe-line-black">
     <div class="container">
        <div class="row">
             <div class="col-md-9 col-sm-8">
                <form class="">
                     <div class="form-group">
                          <input type="text" value="" class="form-control" placeholder="Enter your email...">
                     </div>
                 </form>
             </div>
             <div class="col-md-3 col-sm-4">
                 <button type="button" class="btn btn-neutral btn-block btn-lg">Join Newsletter</button>
             </div>
        </div>
     </div>
</div>

<footer class="footer footer-big">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-9">
                <div class="links">
                    <ul class="uppercase-links">
                        <li>
                            <a href="javascript:void(0)">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                Company
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                Portfolio
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                               Team
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                               Blog
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                               Contact
                            </a>
                        </li>


                    </ul>
                    <hr />

                    <div class="copyright">
                        © {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Creative Tim
                    </div>
                </div>
            </div>

            <div class="col-md-4 ml-auto col-sm-2">
                <div class="social-area">
                    <a href="javascript:void(0)" class="btn btn-neutral btn-round btn-just-icon btn-facebook">
                       <i class="fa fa-facebook" aria-hidden="true"></i>
                   </a>
                    <a href="javascript:void(0)" class="btn btn-neutral btn-just-icon btn-round btn-twitter">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a href="javascript:void(0)" class="btn btn-neutral btn-just-icon btn-round btn-google">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                    <a href="#pin" class="btn btn-neutral btn-just-icon btn-round btn-pinterest">
                        <i class="fa fa-pinterest-p" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>




</div> <!-- wrapper -->
