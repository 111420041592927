<div class="background-img" style="background-image: url('./assets/img/sections/the-how-photographer.jpg')">
    <div class="filter"></div>
    <div class="container">
        <div class="row">
            <h1 class="title"> &nbsp;500 <br/>
                <p class="error-msg">We're sorry, but something went wrong. We are working an fixing this. <br/> Please refresh the page in a couple of seconds.</p>
            </h1>
        </div>
        <div class="container-cards space-top">
            <div class="row">
                <h5 class="discover-pages text-center">Meanwhie, you can check our social pages...</h5>
            </div>
            <div class="offset-md-5 social-buttons">
                <a class="btn btn-just-icon btn-lg btn-facebook" href="https://www.facebook.com/CreativeTim" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                <a class="btn btn-just-icon btn-lg btn-twitter" href="https://twitter.com/CreativeTim" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                <a class="btn btn-just-icon btn-lg btn-linkedin" href="https://www.instagram.com/creativetimofficial/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
            </div>
        </div>
    </div>
</div>
