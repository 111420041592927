<div class="wrapper">
    <div class="main">
        <div class="section section-white">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 ml-auto mr-auto text-center title">
                        <h2>A place for storytelling</h2>
                        <h3 class="title-uppercase"><small>Written by designers for designers</small></h3>
                    </div>
                </div>
                <div class="article">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
							<div class="card card-blog card-plain text-center">
								<div class="card-image">
									<a href="javascript:void(0)">
										<img class="img img-raised" src="./assets/img/sections/bruno-abatti.jpg">
									</a>
								</div>
								<div class="card-body">
									<div class="card-category">
										<span class="label label-primary main-tag">Featured</span>
									</div>
									<a href="javascrip: void(0);">
										<h3 class="card-title">My Review of Pitchfork’s ‘Indie 500’ Album Review</h3>
									</a>
									<div class="card-description">
										<p>In the first sentence of Pitchfork’s review of my collaborative project with 9th Wonder, INDIE 500, a reviewer who is associated with music review site rhapsody.com writes about how I criticize and then distance myself from “celebrity straw men” with the line “celebrities be making money...</p>
									</div>
								</div>
								<button class="btn btn-danger btn-round btn-sm">Read more</button>
							</div>
                        </div>
                    </div>
                </div>
                <div class="article">
                    <div class="row">
						<div class="col-md-8 ml-auto mr-auto">
							<div class="card card-blog card-plain text-center">
								<div class="card-image">
									<a href="javascript:void(0)">
										<img class="img img-raised" src="./assets/img/sections/federico-beccari.jpg">
									</a>
								</div>
								<div class="card-body">
									<div class="card-category">
										<span class="label label-info main-tag">Popular</span>
									</div>
									<a href="javascrip: void(0);">
										<h3 class="card-title">You Should Get Excited About Virtual Reality.</h3>
										<h6 class="title-uppercase">October 20, 2016</h6>
									</a>
									<div class="card-description">
										<p>In the first sentence of Pitchfork’s review of my collaborative project with 9th Wonder, INDIE 500, a reviewer who is associated with music review site rhapsody.com writes about how I criticize and then distance myself from “celebrity straw men” with the line “celebrities be making money...</p>
									</div>
								</div>
								<button class="btn btn-danger btn-round btn-sm">Read more</button>
							</div>
                        </div>
                    </div>
                </div>
                <div class="article">
					<div class="col-md-8 ml-auto mr-auto">
						<div class="card card-blog card-plain text-center">
							<div class="card-image">
								<a href="javascript:void(0)">
									<img class="img img-raised" src="./assets/img/sections/leonard-cotte.jpg">
									<p class="image-thumb">Photo by Cam Adams</p>
								</a>
							</div>
							<div class="card-body">
								<div class="card-category">
									<span class="label label-warning main-tag">Trending</span>
								</div>
								<a href="javascrip: void(0);">
									<h3 class="card-title">Make Somebody Nervous Before You Die</h3>
									<h6 class="title-uppercase">October 20, 2016</h6>
								</a>
								<div class="card-description">
									<p>You won’t find many concepts that are very useful or important if you insist on having a worldview that’s void of controversy, invulnerable to criticism, and incapable of making others feel confused...</p>
								</div>
							</div>
							<button class="btn btn-danger btn-round btn-sm">Read more</button>
						</div>
					</div>

                </div>
                <hr />
                <div class="row">
                    <div class="col-md-12">
                        <div class="pull-left">
                            <button class="btn btn-link btn-default btn-move-left btn-sm"> <i class="fa fa-angle-left"></i> Older Posts</button>
                        </div>
						<div class="pull-right">
                            <button class="btn btn-link btn-default btn-move-right btn-sm">Newer Posts  <i class="fa fa-angle-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<footer class="footer footer-gray">
	<div class="container">
		<div class="row">
			<nav class="footer-nav">
				<ul>
					<li><a href="https://www.creative-tim.com">Creative Tim</a></li>
					<li><a href="http://blog.creative-tim.com">Blog</a></li>
					<li><a href="https://www.creative-tim.com/license">Licenses</a></li>
				</ul>
			</nav>
			<div class="credits ml-auto">
				<span class="copyright">
					© {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Creative Tim
				</span>
			</div>
		</div>
	</div>
</footer>
