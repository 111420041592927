<div class="section section-team cd-section" id="teams">

<!--     *********    TEAM 1     *********      -->
    <div class="team-1">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">Our Stunning Team 1</h2>
                    <h5 class="description">This is the paragraph where you can write more details about your team. Keep you user engaged by providing meaningful information.</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="card card-profile card-plain">
                        <div class="card-avatar">
                            <a href="#avatar"><img src="assets/img/faces/clem-onojeghuo-3.jpg" alt="..."></a>
                        </div>
                        <div class="card-body">
                            <a href="javascript:void(0)">
                                <div class="author">
                                    <h4 class="card-title">Henry Ford</h4>
                                    <h6 class="card-category text-muted">Product Manager</h6>
                                </div>
                            </a>
                            <p class="card-description text-center">
                            Teamwork is so important that it is virtually impossible for you to reach the heights of your capabilities or make the money that you want without becoming very good at it.
                            </p>
                        </div>
                        <div class="card-footer text-center">
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-twitter"><i class="fa fa-twitter"></i></a>
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-dribbble"><i class="fa fa-dribbble"></i></a>
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-linkedin"><i class="fa fa-linkedin"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-profile card-plain">
                        <div class="card-avatar">
                            <a href="#avatar"><img src="assets/img/faces/joe-gardner-2.jpg" alt="..."></a>
                        </div>
                        <div class="card-body">
                            <a href="javascript:void(0)">
                                <div class="author">
                                    <h4 class="card-title">Sophie West</h4>
                                    <h6 class="card-category text-muted">Designer</h6>
                                </div>
                            </a>
                            <p class="card-description text-center">
                            A group becomes a team when each member is sure enough of himself and his contribution to praise the skill of the others. No one can whistle a symphony. It takes an orchestra to play it.
                            </p>
                        </div>
                        <div class="card-footer text-center">
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-twitter"><i class="fa fa-twitter"></i></a>
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-dribbble"><i class="fa fa-dribbble"></i></a>
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-linkedin"><i class="fa fa-linkedin"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-profile card-plain">
                        <div class="card-avatar">
                            <a href="#avatar"><img src="assets/img/faces/erik-lucatero-2.jpg" alt="..."></a>
                        </div>
                        <div class="card-body">
                            <a href="javascript:void(0)">
                                <div class="author">
                                    <h4 class="card-title">Robert Orben</h4>
                                    <h6 class="card-category text-muted">Developer</h6>
                                </div>
                            </a>
                            <p class="card-description text-center">
                            The strength of the team is each individual member. The strength of each member is the team. If you can laugh together, you can work together, silence isn’t golden, it’s deadly.
                            </p>
                        </div>
                        <div class="card-footer text-center">
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-twitter"><i class="fa fa-twitter"></i></a>
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-dribbble"><i class="fa fa-dribbble"></i></a>
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-linkedin"><i class="fa fa-linkedin"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-profile card-plain">
                        <div class="card-avatar">
                            <a href="#avatar"><img src="assets/img/faces/ayo-ogunseinde-2.jpg" alt="..."></a>
                        </div>
                        <div class="card-body">
                            <a href="javascript:void(0)">
                                <div class="author">
                                    <h4 class="card-title">Patrick Lencioni</h4>
                                    <h6 class="card-category text-muted">Brand Manager</h6>
                                </div>
                            </a>
                            <p class="card-description text-center">
                            Great teams do not hold back with one another. They are unafraid to air their dirty laundry. They admit their mistakes, their weaknesses and their concerns without fear of reprisal.
                            </p>
                        </div>
                        <div class="card-footer text-center">
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-twitter"><i class="fa fa-twitter"></i></a>
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-dribbble"><i class="fa fa-dribbble"></i></a>
                            <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-linkedin"><i class="fa fa-linkedin"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


<!--     *********    TEAM 2     *********      -->
    <div class="team-2 section-image" style="background-image: url('assets/img/sections/jan-sendereks.jpg')">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">The Executive Team 2</h2>
                    <h5 class="description">This is the paragraph where you can write more details about your team. Keep you user engaged by providing meaningful information.</h5>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="card card-profile card-plain">
                        <div class="card-img-top">
                            <a href="javascript:void(0)">
                                <img class="img" src="assets/img/faces/kaci-baum-1.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Alec Thompson</h4>
                            <h6 class="card-category">Managing Partner</h6>
                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon"><i class="fa fa-twitter"></i></a>
                                <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon"><i class="fa fa-facebook"></i></a>
                                <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon"><i class="fa fa-dribbble"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-profile card-plain">
                        <div class="card-img-top">
                            <a href="javascript:void(0)">
                                <img class="img" src="assets/img/faces/clem-onojeghuo-1.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">George Lincoln</h4>
                            <h6 class="card-category">Backend Hacker</h6>

                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon"><i class="fa fa-linkedin"></i></a>
                                <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon"><i class="fa fa-facebook"></i></a>
                                <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon"><i class="fa fa-dribbble"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-profile card-plain">
                        <div class="card-img-top">
                            <a href="javascript:void(0)">
                                <img class="img" src="assets/img/faces/joe-gardner-1.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Sophie West</h4>
                            <h6 class="card-category">Photographer</h6>

                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon"><i class="fa fa-dribbble"></i></a>
                                <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon"><i class="fa fa-twitter"></i></a>
                                <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon"><i class="fa fa-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


<!--     *********    TEAM 3     *********      -->
    <div class="team-3">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">The Executive Team 3</h2>
                    <h5 class="description">This is the paragraph where you can write more details about your team. Keep you user engaged by providing meaningful information.</h5>
                </div>
            </div>
            <div class="space-top"></div>
            <div class="row">
                <div class="col-md-6">
                    <div class="card card-profile card-plain">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="card-img-top">
                                    <a href="javascript:void(0)">
                                        <img class="img" src="assets/img/faces/clem-onojeghuo-3.jpg" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="card-body text-left">
                                    <h4 class="card-title">Alec Thompson</h4>
                                    <h6 class="card-category">Founder</h6>
                                    <p class="card-description">
                                        Individual commitment to a group effort – that’s what makes a team work, a society work...
                                    </p>
                                    <div class="card-footer pull-left">
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-twitter"><i class="fa fa-twitter"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-facebook"><i class="fa fa-facebook"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-google"><i class="fa fa-google-plus"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card card-profile card-plain">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="card-img-top">
                                    <a href="javascript:void(0)">
                                        <img class="img" src="assets/img/faces/ayo-ogunseinde-2.jpg" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="card-body text-left">
                                    <h4 class="card-title">Ethan Smith</h4>
                                    <h6 class="card-category">Founder</h6>
                                    <p class="card-description">
                                        Don't be scared of the truth because we need to restart the human foundation in truth...
                                    </p>
                                    <div class="card-footer pull-left">
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-linkedin"><i class="fa fa-linkedin"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-dribbble"><i class="fa fa-dribbble"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-pinterest"><i class="fa fa-pinterest"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card card-profile card-plain">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="card-img-top">
                                    <a href="javascript:void(0)">
                                        <img class="img" src="assets/img/faces/clem-onojeghuo-2.jpg" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="card-body text-left">
                                    <h4 class="card-title">Lucas Andrew</h4>
                                    <h6 class="card-category">Web Designer</h6>
                                    <p class="card-description">
                                        I love you like Kanye loves Kanye. Don't be scared of the truth. Kanye loves himself like Kanye loves Kanye.
                                    </p>
                                    <div class="card-footer pull-left">
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-youtube"><i class="fa fa-youtube"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-twitter"><i class="fa fa-twitter"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-instagram"><i class="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card card-profile card-plain">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="card-img-top">
                                    <a href="javascript:void(0)">
                                        <img class="img" src="assets/img/faces/kaci-baum-2.jpg" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="card-body text-left">
                                    <h4 class="card-title">Sophie West</h4>
                                    <h6 class="card-category">Backend Hacker</h6>
                                    <p class="card-description">
                                        It is literally true that you can succeed best and quickest by helping others to succeed because they care about.
                                    </p>
                                    <div class="card-footer pull-left">
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-linkedin"><i class="fa fa-linkedin"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-instagram"><i class="fa fa-instagram"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-dribbble"><i class="fa fa-dribbble"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


<!--     *********    TEAM 4     *********      -->
    <div class="team-4 section-image" style="background-image: url('assets/img/sections/fabio-mangione.jpg')">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">The Executive Team 4</h2>
                    <h5 class="description">This is the paragraph where you can write more details about your team. Keep you user engaged by providing meaningful information.</h5>
                    <br />
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="card card-profile">
                        <div class="card-body">
                            <div class="card-avatar">
                                <a href="#avatar">
                                    <img src="assets/img/faces/ayo-ogunseinde-2.jpg" alt="..."/>
                                    <h4 class="card-title">Eric Thomson</h4>
                                </a>
                            </div>
                            <p class="card-description text-center">
                                A group becomes a team when each member is sure enough of himself and his contribution to praise the skill of the others. No one can whistle a symphony. It takes an orchestra to play it.
                            </p>
                        </div>
                        <div class="card-footer text-center">
                            <a href="javascript:void(0)" class="btn btn-just-icon btn-linkedin mr-1"><i class="fa fa-linkedin"></i></a>
                            <a href="javascript:void(0)" class="btn btn-just-icon btn-dribbble mr-1"><i class="fa fa-dribbble"></i></a>
                            <a href="javascript:void(0)" class="btn btn-just-icon btn-instagram"><i class="fa fa-instagram"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-profile">
                        <div class="card-body">
                            <div class="card-avatar">
                                <a href="#avatar">
                                    <img src="assets/img/faces/joe-gardner-2.jpg" alt="..."/>
                                    <h4 class="card-title">Sophia West</h4>
                                </a>
                            </div>
                            <p class="card-description text-center">
                                The strength of the team is each individual member. The strength of each member is the team. If you can laugh together, you can work together, silence isn’t golden, it’s deadly.
                            </p>
                        </div>
                        <div class="card-footer text-center">
                            <a href="javascript:void(0)" class="btn btn-just-icon btn-linkedin mr-1"><i class="fa fa-linkedin"></i></a>
                            <a href="javascript:void(0)" class="btn btn-just-icon btn-dribbble mr-1"><i class="fa fa-dribbble"></i></a>
                            <a href="javascript:void(0)" class="btn btn-just-icon btn-pinterest"><i class="fa fa-pinterest"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-profile">
                        <div class="card-body">
                            <div class="card-avatar">
                                <a href="#avatar">
                                    <img src="assets/img/faces/clem-onojeghuo-2.jpg" alt="..."/>
                                    <h4 class="card-title">Lucas Andrew</h4>
                                </a>
                            </div>
                            <p class="card-description text-center">
                                Great teams do not hold back with one another. They are unafraid to air their dirty laundry. They admit their mistakes, their weaknesses and their concerns without fear of reprisal.
                            </p>
                        </div>
                        <div class="card-footer text-center">
                            <a href="javascript:void(0)" class="btn btn-just-icon btn-youtube mr-1"><i class="fa fa-youtube"></i></a>
                            <a href="javascript:void(0)" class="btn btn-just-icon btn-twitter mr-1"><i class="fa fa-twitter"></i></a>
                            <a href="javascript:void(0)" class="btn btn-just-icon btn-instagram"><i class="fa fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


<!--     *********    TEAM 5     *********      -->
    <div class="team-5 section-image" style="background-image: url('assets/img/sections/martin-knize.jpg')">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">The Executive Team 5</h2>
                    <h5 class="description">This is the paragraph where you can write more details about your team. Keep you user engaged by providing meaningful information.</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="card card-profile card-plain">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="card-img-top">
                                    <a href="javascript:void(0)">
                                        <img class="img" src="assets/img/faces/erik-lucatero-2.jpg" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="card-body text-left">
                                    <h4 class="card-title">Andrew Carnegie</h4>
                                    <h6 class="card-category">Project Manager</h6>
                                    <p class="card-description">
                                        If a team is to reach its potential, each player must be willing to subordinate his personal goals.
                                    </p>
                                    <div class="card-footer">
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-twitter"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-facebook"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-google-plus"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card card-profile card-plain">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="card-img-top">
                                    <a href="javascript:void(0)">
                                        <img class="img" src="assets/img/faces/kaci-baum-2.jpg" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="card-body text-left">
                                    <h4 class="card-title">Alec Thompson</h4>
                                    <h6 class="card-category">Founder</h6>
                                    <p class="card-description">
                                        Interdependent people combine their own efforts with the efforts of others to achieve their greatest success.
                                    </p>
                                    <div class="card-footer">
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-linkedin"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-dribbble"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-pinterest"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card card-profile card-plain">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="card-img-top">
                                    <a href="javascript:void(0)">
                                        <img class="img" src="assets/img/faces/clem-onojeghuo-3.jpg" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="card-body text-left">
                                    <h4 class="card-title">Gine Andrew</h4>
                                    <h6 class="card-category">Web Designer</h6>
                                    <p class="card-description">
                                        When he took time to help the man up the mountain, lo, he scaled it himself. Sticks in a bundle are unbreakable.
                                    </p>
                                    <div class="card-footer">
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-youtube"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-twitter"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card card-profile card-plain">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="card-img-top">
                                    <a href="javascript:void(0)">
                                        <img class="img" src="assets/img/faces/clem-onojeghuo-2.jpg" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="card-body text-left">
                                    <h4 class="card-title">George West</h4>
                                    <h6 class="card-category">Backend Hacker</h6>
                                    <p class="card-description">
                                        I love you like Kanye loves Kanye. Don't be scared of the truth because we need to restart the human foundation.
                                    </p>
                                    <div class="card-footer">
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-linkedin"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-instagram"></i></a>
                                        <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral"><i class="fa fa-dribbble"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
