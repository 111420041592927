<div class="section section-project cd-section" id="projects">

    <!--     *********    PROJECTS 1     *********      -->

    <div class="projects-1">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">Some of Our Awesome Products - 1</h2>
                    <h5 class="description"> This is the paragraph where you can write more details about your projects.
                        Keep you user engaged by providing meaningful information.</h5>
                </div>
                <div class="project-pills">
                    <ul ngbNav #nav2="ngbNav" [(activeId)]="activeTab" type="pills"
                        class="nav nav-pills nav-pills-danger justify-content-center">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                All categories
                            </a>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                Marketing
                            </a>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink>
                                Development
                            </a>
                        </li>
                        <li [ngbNavItem]="4">
                            <a ngbNavLink>
                                Productivity
                            </a>
                        </li>
                        <li [ngbNavItem]="5">
                            <a ngbNavLink>
                                Web Design
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="space-top"></div>
            <div class="row">
                <div class="col-md-5">
                    <div class="card" data-background="image"
                        style="background-image: url('assets/img/sections/pavel-kosov.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">Productivy Apps</h6>
                            <a href="javascript:void(0)">
                                <h3 class="card-title">The Best Productivity Apps</h3>
                            </a>
                            <p class="card-description">
                                Don't be scared of the truth because we need to restart the human foundation in truth
                                And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
                            </p>
                            <br />
                            <a href="javascript:void(0)" class="btn btn-danger btn-round">
                                <i class="fa fa-book" aria-hidden="true"></i> Read Article
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="card" data-background="image"
                        style="background-image: url('assets/img/sections/gukhwa-jang.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">Materials</h6>
                            <h3 class="card-title">US venture investment ticks up</h3>
                            <p class="card-description">
                                Venture investment in U.S. startups rose sequentially in the second quarter of 2017,
                                boosted by large, late-stage financings and a few outsized early-stage rounds....
                            </p>
                            <br />
                            <a href="javascript:void(0)" class="btn btn-primary btn-round">
                                <i class="fa fa-book" aria-hidden="true"></i> Read Article
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-7">
                    <div class="card" data-background="image"
                        style="background-image: url('assets/img/sections/joshua-stannard.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">Productivy Apps</h6>
                            <a href="javascript:void(0)">
                                <h3 class="card-title">MateLabs mixes machine</h3>
                            </a>
                            <p class="card-description">
                                If you’re not familiar with IFTTT, it’s an automation tool for creating your own if/then
                                statements without any programming knowledge. The service makes it possible...
                            </p>
                            <br />
                            <a href="javascript:void(0)" class="btn btn-info btn-round">
                                <i class="fa fa-book" aria-hidden="true"></i> Read Article
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="card" data-background="image"
                        style="background-image: url('assets/img/sections/ilya-yakover.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">Materials</h6>
                            <h3 class="card-title">How to find the ‘right’ contacts.</h3>
                            <p class="card-description">
                                Boom, the invitations start flying and Brella makes it easy to accept/decline, schedule
                                a time and reserve a meeting space in Disrupt’s white-glove CrunchMatch meeting...
                            </p>
                            <br />
                            <a href="javascript:void(0)" class="btn btn-warning btn-round">
                                <i class="fa fa-book" aria-hidden="true"></i> Read Article
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--     *********    PROJECTS 2     *********      -->

    <div class="projects-2 section section-dark">

        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h6 class="text-muted">Our work</h6>
                    <h2 class="title">Some of Our Awesome Products - 2</h2>
                    <h5 class="description">This is the paragraph where you can write more details about your projects.
                        Keep you user engaged by providing meaningful information.</h5>
                </div>
            </div>
            <div class="space-top"></div>
            <div class="row">
                <div class="col-md-4">
                    <div class="card card-plain">
                        <div class="card-img-top">
                            <a href="https://www.creative-tim.com/product/paper-kit-2-angular">
                                <img class="img" src="assets/img/sections/opt_pk2_angular_thumbnail.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <a href="https://www.creative-tim.com/product/paper-kit-2-angular" target="_blank">
                                <h4 class="card-title">Paper Kit Angular</h4>
                            </a>
                            <h6 class="card-category text-muted">Free Ui kit</h6>
                            <p class="card-description">
                                Paper Kit Angular is a free Bootstrap UI kit that can get you going in a new creative
                                direction! To say the least, it's different since it's not material, flat or iOS
                                inspired.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-plain">
                        <div class="card-img-top">
                            <a href="https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-angular2">
                                <img class="img" src="assets/img/sections/opt_lbdp_angular_thumbnail.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <a href="https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-angular2"
                                target="_blank">
                                <h4 class="card-title">Light Bootstrap Dashboard Pro Angular</h4>
                            </a>
                            <h6 class="card-category text-muted">Premium template</h6>
                            <p class="card-description">
                                Light Bootstrap Dashboard PRO Angular is a Bootstrap Admin Theme designed to look simple
                                and beautiful. Forget about boring dashboards and grab yourself a copy to kickstart new
                                project!
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-plain">
                        <div class="card-img-top">
                            <a href="https://www.creative-tim.com/product/get-shit-done-pro">
                                <img class="img" src="assets/img/sections/opt_gsdk_new_thumbnail.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <a href="https://www.creative-tim.com/product/get-shit-done-pro" target="_blank">
                                <h4 class="card-title">Get Shit Done Kit Pro</h4>
                            </a>
                            <h6 class="card-category text-muted">Premium UI kit</h6>
                            <p class="card-description">
                                Get Shit Done Kit Pro it's a Bootstrap Kit that comes with a huge number of customisable
                                components. They are pixel perfect, light and easy to use and combine with other
                                elements.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--     *********    PROJECTS 3     *********      -->

    <div class="projects-3" id="projects-3">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <div class="space-top"></div>
                    <h6 class="category">Our work</h6>
                    <h2 class="title">Some of Our Awesome Projects - 3</h2>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="card card-profile card-plain">
                        <div class="card-img-top">
                            <a href="https://www.creative-tim.com/product/paper-kit-2-angular">
                                <img class="img" src="assets/img/sections/angelo-pantazis.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="card-category">Productivity tools</h6>
                            <h4 class="card-title">Beautiful Desktop for Designers</h4>
                            <p class="card-description">
                                As this technology matures it will be cool to see what hackers are able to do with it.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 ">
                    <div class="card card-profile card-plain">
                        <div class="card-img-top">
                            <a href="https://www.creative-tim.com/product/paper-kit-2-angular">
                                <img class="img" src="assets/img/sections/patrick-tomasso.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="card-category">Web Design</h6>
                            <h4 class="card-title">Famous Website Redesign Implementation</h4>
                            <p class="card-description">
                                Streaming services once again top the list of this year’s Emmy nominations
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-profile card-plain">
                        <div class="card-img-top">
                            <a href="https://www.creative-tim.com/product/paper-kit-2-angular">
                                <img class="img" src="assets/img/sections/luke-chesser.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="card-category">Marketing tools</h6>
                            <h4 class="card-title">The Best Productivity Applications</h4>
                            <p class="card-description">
                                Dietary supplements don’t need approval from the Food and Drug Administration.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-profile card-plain">
                        <div class="card-img-top">
                            <a href="https://www.creative-tim.com/product/paper-kit-2-angular">
                                <img class="img" src="assets/img/sections/rawpixel-coms.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="card-category">Website</h6>
                            <h4 class="card-title">Behance Redesign</h4>
                            <p class="card-description">
                                The HDR rollout is being enabled via support from two major studio partners.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-profile card-plain">
                        <div class="card-img-top">
                            <a href="https://www.creative-tim.com/product/paper-kit-2-angular">
                                <img class="img" src="assets/img/sections/patrick-tomasso.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="card-category">Android App</h6>
                            <h4 class="card-title">Analytics for Android</h4>
                            <p class="card-description">
                                Google did not offer an estimate as to when HDR support reaches its other global
                                markets.
                            </p>
                        </div>
                    </div>

                </div>

                <div class="col-md-4">
                    <div class="card card-profile card-plain">
                        <div class="card-img-top">
                            <a href="https://www.creative-tim.com/product/paper-kit-2-angular">
                                <img class="img" src="assets/img/sections/rawpixel-coms.jpg" />
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="card-category">Material</h6>
                            <h4 class="card-title">How to find the contacts.</h4>
                            <p class="card-description">
                                Boom, the invitations start flying and Brella makes it easy to accept/decline
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--     *********    PROJECTS 4     *********      -->

    <div class="project-4 section section-dark">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">Some of Our Awesome Projects - 4</h2>
                    <h5 class="description">This is the paragraph where you can write more details about your projects.
                        Keep you user engaged by providing meaningful information.</h5>
                </div>
            </div>
            <div class="space-top"></div>
            <div class="row">
                <div class="col-md-5 ml-auto">
                    <div class="card" data-background="image"
                        style="background-image: url('assets/img/sections/sebastien-gabrieles.jpg')">
                        <div class="card-icon">
                            <i class="nc-icon nc-mobile"></i>
                        </div>
                    </div>
                </div>

                <div class="col-md-5 mr-auto">
                    <div class="card card-plain">
                        <div class="card-body">
                            <h6 class="card-category">Technology</h6>
                            <a href="javascript:void(0)">
                                <h3 class="card-title">The need of a great product</h3>
                            </a>
                            <p class="card-description">Artificial intelligence-focused investment funds are a dime a
                                dozen these days. Everyone knows there’s money to be made from AI, but to capture value,
                                good VCs know they need to back products and not technologies.</p>

                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-link btn-neutral">
                                    <i class="fa fa-book" aria-hidden="true"></i> Read More
                                </a>
                                <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral">
                                    <i class="fa fa-apple" aria-hidden="true"></i>
                                </a>
                                <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral">
                                    <i class="fa fa-android" aria-hidden="true"></i>
                                </a>
                                <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral">
                                    <i class="fa fa-windows" aria-hidden="true"></i>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <hr /><br />
            <div class="row">
                <div class="col-md-5 ml-auto">
                    <div class="card card-plain">
                        <div class="card-body">
                            <h6 class="card-category">Design</h6>
                            <a href="javascript:void(0)">
                                <h3 class="card-title">The aesthetic quality of a product</h3>
                            </a>
                            <p class="card-description">Eventually, the data collected from the grant program could
                                allow the two to play a bit of machine learning moneyball — valuing machine learning
                                engineers without traditional metrics (like having a PhD from Stanford)...</p>

                            <div class="card-footer">
                                <a href="javascript:void(0)" class="btn btn-link btn-neutral">
                                    <i class="fa fa-book" aria-hidden="true"></i> Read More
                                </a>
                                <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral">
                                    <i class="fa fa-apple" aria-hidden="true"></i>
                                </a>
                                <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral">
                                    <i class="fa fa-android" aria-hidden="true"></i>
                                </a>
                                <a href="javascript:void(0)" class="btn btn-just-icon btn-link btn-neutral">
                                    <i class="fa fa-windows" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-5 mr-auto">
                    <div class="card" data-background="image"
                        style="background-image: url('assets/img/sections/john-towner.jpg')">
                        <div class="card-icon">
                            <i class="nc-icon nc-controller-modern"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>