<div class="page-header">
  <div class="page-header-image rellax-header" data-rellax-speed="-7" style="background-image: url('./assets/img/sections/uriel-soberanes.jpg')">

  </div>
    <div class="filter"></div>
    <div class="content-center">
        <div class="container">
            <div class="motto">
                <h1 class="title">Morango</h1>
                <h3 class="description">Estamos preparando algo incrível para você !</h3>
                <br />
                
                <a href="https://www.instagram.com/" class="btn btn-link btn-neutral"><img src="./assets/img/instagram.png" width="60">
            <br><br>@Morango Loja</a>
            </div>
        </div>
    </div>

</div>


<footer class="footer section-dark">
    <div class="container">
        <div class="row">
            <nav class="footer-nav">
                <ul>
                    <li><a href="https://www.creative-tim.com">Contact</a></li>
                    <li><a href="http://blog.creative-tim.com">Impressum</a></li>
                    <li><a href="#">Licenses</a></li>
                </ul>
            </nav>
            <div class="credits ml-auto">
                <span class="copyright">
                    © {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> 
                </span>
            </div>
        </div>
    </div>
</footer>
