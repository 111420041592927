<div class="section section-testimonials cd-section" id="testimonials">

<!--     *********    TESTIMONIALS 1     *********      -->

    <div class="testimonials-1 section-image" style="background-image: url('assets/img/sections/uriel-soberanes.jpg')">
        <div class="container">
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                    <h2 class="title">Our Clients Love Us</h2>
                    <h5 class="description">You need more information? Check what other persons are saying about our product. They are very happy with their purchase.</h5>
                </div>
            </div>
            <div class="space-top"></div>
            <div class="row">
                <div class="col-md-4">
                    <div class="card card-testimonial">
                        <div class="card-icon">
                            <i class="fa fa-quote-right" aria-hidden="true"></i>
                        </div>
                        <div class="card-body">
                            <p class="card-description">
                                Your products, all the kits that I have downloaded from your site and worked with are sooo cool! I love the color mixtures, cards... everything. Keep up the great work!
                            </p>
                            <div class="card-footer">
                                <h4 class="card-title">Alec Thompson</h4>
                                <h6 class="card-category">@alecthompson</h6>
                                <div class="card-avatar">
                                    <a href="javascript:void(0)">
                                        <img class="img" src="assets/img/faces/ayo-ogunseinde-2.jpg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-testimonial">
                        <div class="card-icon">
                            <i class="fa fa-quote-right" aria-hidden="true"></i>
                        </div>
                        <div class="card-body">
                            <p class="card-description">
                                "Don't be scared of the truth because we need to restart the human foundation in truth. That's why I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is not so attractive"
                            </p>
                            <div class="card-footer">
                                <h4 class="card-title">Gina Andrew</h4>
                                <h6 class="card-category">@ginaandrew</h6>
                                <div class="card-avatar">
                                    <a href="javascript:void(0)">
                                        <img class="img" src="assets/img/faces/joe-gardner-2.jpg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card card-testimonial">
                        <div class="card-icon">
                            <i class="fa fa-quote-right" aria-hidden="true"></i>
                        </div>
                        <div class="card-body">
                            <p class="card-description">
                                "Your products, all the kits that I have downloaded from your site and worked with are sooo cool! I love the color mixtures, cards... everything. Keep up the great work!"
                            </p>
                            <div class="card-footer">
                                <h4 class="card-title">Joe West</h4>
                                <h6 class="card-category">@georgewest</h6>
                                <div class="card-avatar">
                                    <a href="javascript:void(0)">
                                        <img class="img" src="assets/img/faces/kaci-baum-2.jpg" />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


<!--     *********    TESTIMONIALS 2     *********      -->

    <div class="testimonials-2 section section-testimonials">
        <div class="container">
            <div class="row">
                <div class="col-md-2 mr-auto">
                    <div class="testimonials-people">
                        <img class="left-first-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/110862/thumb.?1482812727" alt="" />
                        <img class="left-second-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/139481/thumb.jpg?1485460613" alt="" />
                        <img class="left-third-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/152441/thumb.jpg?1488233314" alt="" />
                        <img class="left-fourth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/134607/thumb.?1487680276" alt="" />
                        <img class="left-fifth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/161506/thumb.?1489848178" alt="" />
                        <img class="left-sixth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/77627/thumb.jpg?1487360092" alt="" />
                    </div>
                </div>

                <div class="col-md-6 ml-auto mr-auto">
                    <div class="page-carousel">
                        <ngb-carousel>
                            <ng-template ngbSlide>
                                <div class="card card-testimonial card-plain">
                                    <div class="card-avatar">
                                        <img class="img" src="https://randomuser.me/api/portraits/men/28.jpg" />
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-description">
                                            "I'm newer to the front-end... With my creative side lacking in experience this really put me in the right spot to speed through the fast lane. I love this Design kit so much!"
                                        </h5>
                                        <div class="card-footer">
                                            <h4 class="card-title">Chase Jackson</h4>
                                            <h6 class="card-category">Web Developer</h6>
                                            <div class="card-stars">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template ngbSlide>
                                <div class="card card-testimonial card-plain">
                                    <div class="card-avatar">
                                        <img class="img" src="https://s3.amazonaws.com/creativetim_bucket/photos/134607/thumb.?1487680276" />
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-description">
                                            "Love the shapes and color palette on this one! Perfect for one of my pet projects!"
                                        </h5>
                                        <div class="card-footer">
                                            <h4 class="card-title">Robin Leysen</h4>
                                            <h6 class="card-category">Web Developer</h6>
                                            <div class="card-stars">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template ngbSlide>
                                <div class="card card-testimonial card-plain">
                                    <div class="card-avatar">
                                        <img class="img" src="https://s3.amazonaws.com/creativetim_bucket/photos/125268/thumb.jpeg?1497799215" />
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-description">
                                            "Love it. Use it for prototypes and along with Paper Dashboard."
                                        </h5>
                                        <div class="card-footer">
                                            <h4 class="card-title">Cristi Jora</h4>
                                            <h6 class="card-category">Web Developer</h6>
                                            <div class="card-stars">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>

                <div class="col-md-2 ml-auto">
                    <div class="testimonials-people">
                        <img class="right-first-person add-animation" src="./assets/img/faces/clem-onojeghuo-3.jpg" alt="" />
                        <img class="right-second-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/125268/thumb.jpeg?1497799215" alt="" />
                        <img class="right-third-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/123334/thumb.JPG?1479459618" alt="" />
                        <img class="right-fourth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/118235/thumb.?1477435947" alt="" />
                        <img class="right-fifth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/18/thumb.png?1431433244" alt="" />
                        <img class="right-sixth-person add-animation" src="https://s3.amazonaws.com/creativetim_bucket/photos/167683/thumb.?1491014996" alt="" />
                    </div>
                </div>

            </div>

        </div>

    </div>


<!--     *********    TESTIMONIALS 3     *********      -->

    <div class="testimonials-3">

      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title">What Clients Say</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 ml-auto">
            <div class="card" data-background="color" data-color="orange">
              <div class="card-body">
                <div class="author">
                  <a href="javascript:void(0)">
                    <img src="./assets/img/faces/joe-gardner-2.jpg" alt="..." class="avatar img-raised">
                    <span>Erik Johnson</span>
                  </a>
                </div>
                <span class="category-social pull-right">
                  <i class="fa fa-quote-right"></i>
                </span>
                <div class="clearfix"></div>
                <p class="card-description">
                  "During the first selection of grant winners on the essential aspects, and the products are not burdened with non-essentials. Back to purity, back to simplicity. At best, it is self-explanatory."
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card" data-background="color" data-color="green">
              <div class="card-body">
                <div class="author">
                  <a href="javascript:void(0)">
                    <img src="./assets/img/faces/erik-lucatero-2.jpg" alt="..." class="avatar img-raised">
                    <span>Erik Johnson</span>
                  </a>
                </div>
                <span class="category-social pull-right">
                  <i class="fa fa-quote-right"></i>
                </span>
                <div class="clearfix"></div>
                <p class="card-description">
                  "The plan is to add additional flexibility in the future to allow applicants to make a case for how much money they actually need. Less, but better – because it concentrates on the essential aspects, and the products are not burdened with non-essentials..."
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3 mr-auto">
            <div class="card" data-background="color" data-color="yellow">
              <div class="card-body">
                <div class="author">
                  <a href="javascript:void(0)">
                    <img src="./assets/img/faces/kaci-baum-2.jpg" alt="..." class="avatar img-raised">
                    <span>Erik Johnson</span>
                  </a>
                </div>
                <span class="category-social pull-right">
                  <i class="fa fa-quote-right"></i>
                </span>
                <div class="clearfix"></div>
                <p class="card-description">
                  "Simulation of many-body quantum systems with neural networks, and the products are not burdened with non-essentials. Back to purity, back to simplicity. At best, it is self-explanatory."
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 ml-auto">
            <div class="card" data-background="color" data-color="blue">
              <div class="card-body">
                <div class="author">
                  <a href="javascript:void(0)">
                    <img src="./assets/img/faces/clem-onojeghuo-2.jpg" alt="..." class="avatar img-raised">
                    <span>Erik Johnson</span>
                  </a>
                </div>
                <span class="category-social pull-right">
                  <i class="fa fa-quote-right"></i>
                </span>
                <div class="clearfix"></div>
                <p class="card-description">
                  "Machine learning for motion recognition and trajectory generation of human movement for rehabilitation with non-essentials. Back to purity, back to simplicity. At best, it is self-explanatory."
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 mr-auto">
            <div class="card" data-background="color" data-color="purple">
              <div class="card-body">
                <div class="author">
                  <a href="javascript:void(0)">
                    <img src="./assets/img/faces/ayo-ogunseinde-2.jpg" alt="..." class="avatar img-raised">
                    <span>Erik Johnson</span>
                  </a>
                </div>
                <span class="category-social pull-right">
                  <i class="fa fa-quote-right"></i>
                </span>
                <div class="clearfix"></div>
                <p class="card-description">
                  "Less, but better – because it concentrates on the essential aspects, and the products are not burdened with non-essentials. Back to purity, back to simplicity. At best, it is self-explanatory. The entire AI Grant project reminds me of a cross between a Thiel Fellowship and a Kaggle competition."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
