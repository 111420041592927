<div class="section sections-prefooter">
    <div class="container tim-container">
        <div class="title" id="subscription">
            <h3>Pre-footer Areas</h3>
        </div>
        <h4><small>Subscription</small></h4>
    </div>
</div>
<div class="subscribe-line">
    <div class="container">
        <div class="row">
            <div class="col-md-9 col-sm-8">
                <form class="">
                    <div class="form-group">
                          <input type="text" value="" class="form-control" placeholder="Enter your email...">
                    </div>
                </form>
            </div>
            <div class="col-md-3 col-sm-4">
                <button type="button" class="btn btn-danger btn-block btn-lg">Subscribe Now!</button>
            </div>
        </div>
    </div>
</div>
<div class="separator"></div>

<div class="subscribe-line subscribe-line-black">
    <div class="container">
        <div class="row">
            <div class="col-md-9 col-sm-8">
                <form class="">
                    <div class="form-group">
                        <input type="text" value="" class="form-control" placeholder="Enter your email...">
                    </div>
                </form>
            </div>
            <div class="col-md-3 col-sm-4">
                <button type="button" class="btn btn-neutral btn-block btn-lg">Join Newsletter</button>
            </div>
        </div>
    </div>
</div>
<div class="separator"></div>

<div class="subscribe-line subscribe-line-transparent" style="background-image: url('assets/img/sections/pavel-kosov.jpg')">
    <div class="container">
        <div class="row">
            <div class="col-md-9 col-sm-8">
                <form class="">
                    <div class="form-group">
                        <input type="text" value="" class="form-control" placeholder="Enter your email...">
                    </div>
                </form>
            </div>
            <div class="col-md-3 col-sm-4">
                <button type="button" class="btn btn-info btn-block btn-round">Subscribe Now!</button>
            </div>
        </div>
    </div>
</div>
<div class="separator"></div>


<div class="section">
  <div class="container tim-container">
    <div class="title">
      <h4>Social and Sharing</h4>
    </div>
  </div>
</div>
<div class="social-line" id="sharing">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <h4 class="title">We are social</h4>
            </div>
            <div class="col-md-2 col-sm-3">
                <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-facebook mr-1">
                    <i class="fa fa-facebook" aria-hidden="true"></i> Facebook
                </a>
            </div>
              <div class="col-md-2 col-sm-3">
                <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-twitter mr-1">
                    <i class="fa fa-twitter" aria-hidden="true"></i> Twitter
                </a>
             </div>
             <div class="col-md-2 col-sm-3">
                <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-dribbble">
                    <i class="fa fa-dribbble" aria-hidden="true"></i> Dribbble
                </a>
            </div>
            <div class="col-md-2 col-sm-3">
                <a href="javascript:void(0)" class="btn btn-link btn-just-icon btn-youtube">
                    <i class="fa fa-youtube" aria-hidden="true"></i> Youtube
                </a>
            </div>
        </div>
    </div>
 </div>

<div class="separator"></div>
<div class="social-line social-line-black">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <h4 class="title">Follow Us</h4>
            </div>
            <div class="col-md-2 col-sm-3">
                <a href="javascript:void(0)" class="btn btn-link btn-neutral mr-1">
                    <i class="fa fa-twitter" aria-hidden="true"></i> Twitter
                </a>
            </div>
            <div class="col-md-2 col-sm-3">
                <a href="javascript:void(0)" class="btn btn-link btn-neutral mr-1">
                    <i class="fa fa-dribbble" aria-hidden="true"></i> Dribbble
                </a>
            </div>
            <div class="col-md-2 col-sm-3">
                <a href="javascript:void(0)" class="btn btn-link btn-neutral mr-1">
                    <i class="fa fa-youtube" aria-hidden="true"></i> Youtube
                </a>
            </div>
            <div class="col-md-2 col-sm-3">
                <a href="javascript:void(0)" class="btn btn-link btn-neutral">
                    <i class="fa fa-google-plus" aria-hidden="true"></i> Google+
                </a>
            </div>
        </div>
    </div>
</div>

<div class="separator"></div>
<div class="social-line" id="social-sharing">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h4 class="title">Thank you for sharing!</h4>
            </div>
            <div class="col-md-2 col-sm-6">
                <a href="javascript:void(0)" class="btn btn-round btn-facebook mr-1">
                    <i class="fa fa-facebook" aria-hidden="true"></i> Share &middot; 753
                </a>
            </div>
            <div class="col-md-2 col-sm-6">
                <a href="javascript:void(0)" class="btn btn-round btn-twitter mr-1">
                    <i class="fa fa-twitter" aria-hidden="true"></i> Tweet &middot; 832
                </a>
            </div>
            <div class="col-md-2 col-sm-6">
                <a href="javascript:void(0)" class="btn btn-round btn-google mr-1">
                    <i class="fa fa-google-plus" aria-hidden="true"></i> Share &middot; 131
                </a>
            </div>
            <div class="col-md-2 col-sm-6">
                <a href="javascript:void(0)" class="btn btn-round btn-youtube">
                    <i class="fa fa-pinterest-p" aria-hidden="true"></i> Pin it &middot; 323
                </a>
            </div>
        </div>
    </div>
</div>
