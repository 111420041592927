<div class="cd-section section section-headers" id="headers">

    <!--     *********     HEADER 1      *********      -->
    <div class="header-1">
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
            <div class="container">
                <a class="navbar-brand mb-0" href="www.creative-tim.com">Creative Tim</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-bar"></span>
                    <span class="navbar-toggler-bar"></span>
                    <span class="navbar-toggler-bar"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <a href="javascript:void(0)" class="btn btn-link btn-neutral">Components</a>
                        <a href="javascript:void(0)" class="btn btn-link btn-neutral">Tutorial</a>
                        <a href="javascript:void(0)" target="_blank" class="btn btn-link btn-neutral"><i class="fa fa-twitter"></i></a>
                        <a href="javascript:void(0)" target="_blank" class="btn btn-link btn-neutral"><i class="fa fa-facebook"></i></a>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="page-header" style="background-image: url('https://images.unsplash.com/photo-1486310662856-32058c639c65?dpr=2&auto=format&fit=crop&w=1500&h=1125&q=80&cs=tinysrgb&crop=');">
            <div class="filter"></div>
            <div class="content-center">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="iframe-container">
                                <iframe src="https://www.youtube.com/embed/RcmrbNRK-jY?modestbranding=1&autohide=1&showinfo=0" frameborder="0" allowfullscreen height="250"></iframe>
                            </div>
                        </div>
                        <div class="col-md-6  ml-auto">
                            <h2 class="title">Travel with us</h2>
                            <h5 class="description">There's no doubt that Tesla is delighted with the interest, but the data also raises a few questions. How long will it take for Tesla to fulfill all those extra orders?</h5>
                            <br />
                            <a href="https://www.youtube.com/watch?v=RcmrbNRK-jY" target="_blank" class="btn btn-danger">
                                <i class="nc-icon nc-cart-simple"></i> Order Now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--     *********     HEADER 2      *********      -->
    <div class="header-2">
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
            <div class="container">
                <a class="navbar-brand mb-0" href="www.creative-tim.com">Creative Tim</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-bar"></span>
                    <span class="navbar-toggler-bar"></span>
                    <span class="navbar-toggler-bar"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <a href="javascript:void(0)" class="btn btn-link btn-neutral">Components</a>
                        <a href="javascript:void(0)" class="btn btn-link btn-neutral">Tutorial</a>
                        <a href="javascript:void(0)" target="_blank" class="btn btn-link btn-neutral"><i class="fa fa-twitter"></i></a>
                        <a href="javascript:void(0)" target="_blank" class="btn btn-link btn-neutral"><i class="fa fa-facebook"></i></a>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="page-header" style="background-image: url('https://images.unsplash.com/photo-1498475932703-000eb46e6375?dpr=2&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=');">
            <div class="filter"></div>
            <div class="content-center">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto text-center">
                            <h1 class="title"> Find your next trip</h1>
                            <h5 class="description">Now you have no excuses, it's time to surprise your clients, your competitors, and why not, the world. You probably won't have a better chance to show off all your potential if it's not by designing a website for your own agency or web studio.</h5>
                            <br />
                        </div>
                        <div class="col-md-10 ml-auto mr-auto">
                            <div class="card card-raised card-form-horizontal no-transition">
                                <div class="card-body">
                                    <form method="" action="">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <input type="text" value="" placeholder="City" class="form-control" />
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <input type="text" value="" placeholder="Country" class="form-control" />
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <input type="text" value="" placeholder="Date" class="form-control" />
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <button type="button" class="btn btn-danger btn-block"><i class="nc-icon nc-zoom-split"></i> &nbsp; Search</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--     *********     HEADER 3      *********      -->

    <div class="header-3">
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
            <div class="container">
                <a class="navbar-brand mb-0" href="www.creative-tim.com">Creative Tim</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-bar"></span>
                    <span class="navbar-toggler-bar"></span>
                    <span class="navbar-toggler-bar"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <a href="javascript:void(0)" class="btn btn-link btn-neutral">Components</a>
                        <a href="javascript:void(0)" class="btn btn-link btn-neutral">Tutorial</a>
                        <a href="javascript:void(0)" target="_blank" class="btn btn-link btn-neutral"><i class="fa fa-twitter"></i></a>
                        <a href="javascript:void(0)" target="_blank" class="btn btn-link btn-neutral"><i class="fa fa-facebook"></i></a>
                    </ul>
                </div>
            </div>
        </nav>

        <div class="page-carousel">
            <div class="filter"></div>
            <ngb-carousel>
                <ng-template ngbSlide>
                    <div class="page-header" style="background-image: url('https://images.unsplash.com/photo-1498063401574-13cbee350467?dpr=2&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=');">
                        <div class="filter"></div>
                        <div class="content-center">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 text-left">
                                        <h1 class="title">Paper Kit 2 PRO</h1>
                                        <h5>Now you have no excuses, it's time to surprise your clients, your competitors, and why not, the world. You probably won't have a better chance to show off all your potential if it's not by designing a website for your own agency or web studio.</h5>
                                        <br />
                                        <div class="buttons">
                                            <a href="javascript:void(0)" class="btn btn-danger btn-round  btn-lg">
                                                Read More
                                            </a>
                                            <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon">
                                                <i class="fa fa-twitter"></i>
                                            </a>
                                            <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon">
                                                <i class="fa fa-facebook-square"></i>
                                            </a>
                                            <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon">
                                                <i class="fa fa-get-pocket"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="page-header" style="background-image: url('https://images.unsplash.com/photo-1482690205767-61deebe15ef7?dpr=2&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=')">
                        <div class="filter"></div>
                        <div class="content-center">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-8 ml-auto mr-auto text-center">
                                        <h1 class="title">Awesome Experiences</h1>
                                        <h5>Now you have no excuses, it's time to surprise your clients, your competitors, and why not, the world. You probably won't have a better chance to show off all your potential if it's not by designing a website for your own agency or web studio.</h5>
                                        <br />
                                        <h6>Connect with us:</h6>
                                        <div class="buttons">
                                            <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon">
                                                <i class="fa fa-twitter"></i>
                                            </a>
                                            <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon">
                                                <i class="fa fa-facebook-square"></i>
                                            </a>
                                            <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon">
                                                <i class="fa fa-instagram"></i>
                                            </a>
                                            <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-just-icon">
                                                <i class="fa fa-google-plus"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="page-header" style="background-image: url('https://images.unsplash.com/photo-1498063669468-89723a87123d?dpr=2&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=')">
                        <div class="filter"></div>
                        <div class="content-center">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-7 ml-auto text-right">
                                        <h2 class="title">Premium Offers for Venice</h2>
                                        <h5>Now you have no excuses, it's time to surprise your clients, your competitors, and why not, the world. You probably won't have a better chance to show off all your potential if it's not by designing a website for your own agency or web studio.</h5>
                                        <br />
                                        <div class="buttons">

                                            <a href="javascript:void(0)" class="btn btn-neutral btn-link btn-lg">
                                                <i class="fa fa-share-alt"></i> Share Offer
                                            </a>
                                            <a href="javascript:void(0)" class="btn btn-success btn-round btn-lg">
                                                <i class="fa fa-shopping-cart"></i> Shop Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>

    <!--     *********     HEADER 4 (w/ video)      *********      -->

    <div class="header-4">
        <div class="header-wrapper">
            <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
                <div class="container">
                    <a class="navbar-brand mb-0" href="www.creative-tim.com">Creative Tim</a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-bar"></span>
                        <span class="navbar-toggler-bar"></span>
                        <span class="navbar-toggler-bar"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ml-auto">
                            <a href="javascript:void(0)" class="btn btn-link btn-neutral">Components</a>
                            <a href="javascript:void(0)" class="btn btn-link btn-neutral">Tutorial</a>
                            <a href="javascript:void(0)" target="_blank" class="btn btn-link btn-neutral"><i class="fa fa-twitter"></i></a>
                            <a href="javascript:void(0)" target="_blank" class="btn btn-link btn-neutral"><i class="fa fa-facebook"></i></a>
                        </ul>
                    </div>
                </div>
            </nav>
            <div class="page-header header-video">
                <div class="filter filter-danger"></div>
                <!-- We show the video image placeholder instead of the video for small devices  -->
                <div class="video-image visible-xs visible-sm" style="background-image: url('assets/img/video-placeholder.png')"></div>

                <video id="video-source" preload="auto" loop="loop" muted="muted" volume="0">
                    <source src="assets/video/fireworks.mp4" type="video/mp4">
                        Video not supported
                </video>
                <div class="content-center">
                    <div class="container upper-container text-center">
                        <div class="video-text">
                            <h2>Make it</h2>
                            <h1 class="title-uppercase title-no-upper-margin">Stand out</h1>
                        </div>
                        <br />
                        <!--  We hide the play button on small devices -->
                        <button type="button" class="btn btn-lg btn-neutral" (click)="playBackgroundVideo($event)">
                            <i class="fa {{icon_video_button_class}}"></i> {{text_video_button}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
