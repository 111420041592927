<div class="wrapper">
    <div class="page-header page-header-small" style="background-image: url('./assets/img/sections/rodrigo-ardilha.jpg');">
        <div class="filter"></div>
    </div>
    <div class="profile-content section">
        <div class="container">
            <div class="row">
                <div class="profile-picture">
                    <div class="fileinput fileinput-new" data-provides="fileinput">
                        <div class="fileinput-new img-no-padding">
                            <img src="./assets/img/faces/joe-gardner-2.jpg" alt="...">
                        </div>
                        <div class="name">
                            <h4 class="title text-center">Chet Faker<br /><small>Music Producer</small></h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                    <p>An artist of considerable range, Chet Faker — the name taken by Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs and records all of his own music, giving it a warm, intimate feel with a solid groove structure. </p>
                    <br />
                    <button class="btn btn-outline-default btn-round"><i class="fa fa-cog"></i> Settings</button>
                </div>
            </div>
            <br/>
            <div class="nav-tabs-navigation">
                <div class="nav-tabs-wrapper">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-tabs justify-content-center">
                        <li class="nav-item" [ngbNavItem]="1">
                            <a class="nav-link" ngbNavLink> Follows</a>
                            <ng-template ngbNavContent >
                                <div class="row following" id="follows">
                                    <div class="col-md-6 ml-auto mr-auto">
                                        <ul class="list-unstyled follows">
                                            <li>
                                                <div class="row">
                                                    <div class="col-md-2 col-3">
                                                        <img src="./assets/img/faces/clem-onojeghuo-3.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                                                    </div>
                                                    <div class="col-md-7 col-4">
                                                        <h6>Lincoln<br/><small>Car Producer</small></h6>
                                                    </div>
                                                    <div class="col-md-3 col-2">
                                                        <div class="unfollow" >
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                                                    <input class="form-check-input" type="checkbox" value="" checked>
                                                                    <span class="form-check-sign"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <hr />
                                            <li>
                                                <div class="row">
                                                    <div class="col-md-2 col-3">
                                                        <img src="./assets/img/faces/erik-lucatero-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                                                    </div>
                                                    <div class="col-md-7 col-4">
                                                        <h6>Banks<br /><small>Singer</small></h6>
                                                    </div>
                                                    <div class="col-md-3 col-2">
                                                        <div class="unfollow" >
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                                                    <input class="form-check-input" type="checkbox" value="">
                                                                    <span class="form-check-sign"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>>
                            </ng-template>
                        </li>
                        <li class="nav-item" [ngbNavItem]="2">
                            <a class="nav-link" ngbNavLink> Following</a>
                            <ng-template ngbNavContent>
                                <h3 class="text-muted">Not following anyone yet :(</h3>
                                <br>
                                <button class="btn btn-warning btn-round">Find artists</button>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="footer section-dark">
    <div class="container">
        <div class="row">
            <nav class="footer-nav">
                <ul>
                    <li><a href="https://www.creative-tim.com">Creative Tim</a></li>
                    <li><a href="http://blog.creative-tim.com">Blog</a></li>
                    <li><a href="https://www.creative-tim.com/license">Licenses</a></li>
                </ul>
            </nav>
            <div class="credits ml-auto">
                <span class="copyright">
                    © {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Creative Tim
                </span>
            </div>
        </div>
    </div>
</footer>
